import React, {useState, useEffect} from 'react';
import {Dialog, DialogContent, DialogTitle, Tooltip,Box} from '@mui/material';
import Heading from '../../Components/Heading/heading.tsx';
import {getDocuments} from './action.ts';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface DocumentsModal {
  open: boolean;
  onClose: () => void;
  driverId: any;
}

const DocumentsModal: React.FC<DocumentsModal> = ({ open, onClose, driverId }) => {
    const [documentList, setDocumentList] = useState<any[]>([]);
    useEffect(() => {
        if (driverId && open) {
          getDocuments(
            '',
            driverId,
            (response: any) => {
              const documentDetails = response.message?.user_documents || [];
              setDocumentList(documentDetails);
             },
            (errMessage: any) => {
                toast.error(errMessage);
            }
           );
        }
    },[driverId, open]);
    const handleIconClick = (type: string, action: string) => {
        if (action === 'view') {
            const document = documentList.find(doc => 
            doc.document_name.toLowerCase() === type.toLowerCase() && doc.status === 'uploaded'
           );
          if (document) {
              const url = document.location;
              window.open(`${global.documents_image_url}${url}`, '_blank');
          } else {
            toast.error('Document not found.');
          }
        }
    };
    const renderFileInput = (label: string, type: string) => {
        const document = documentList.find(doc => 
        doc.document_name.toLowerCase() === type.toLowerCase() && doc.status === 'uploaded'
        );
      return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ marginRight: '10px', width: '180px' }}>{label}</p>
          <input
            accept="*"
            style={{ display: 'none' }}
            id={`${type}-file`}
            type="file"
          />
          {document && (
            <Tooltip title="View File">
              <span
                style={{ cursor: 'pointer', color: 'blue', marginRight: '10px' }}
                onClick={() => handleIconClick(type, 'view')}
              >
                View
              </span>
            </Tooltip>
          )}
        </div>
      </div>
    );
  };
  
  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { width: '300px', height: 'auto' } }}>
      <DialogTitle>
      <Box sx={{ marginLeft: {
      sm: '-180px', 
    }, }}>
          <Heading title='Documents' closeIcon="true" onClose={onClose} />
        </Box>
      </DialogTitle>
      <DialogContent>
        {renderFileInput('Aadhar:', 'aadhar')}
        {renderFileInput('Driving License:', 'driving license')}
      </DialogContent>
    </Dialog>
  );
};

export default DocumentsModal;
