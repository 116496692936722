import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import '../../Components/Button/button-style.scss';
import Heading from '../../Components/Heading/heading.tsx';

interface ConfirmationPopupProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  contentToDisplay?: React.ReactNode;
}

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({ open, onClose, onConfirm, title, message , contentToDisplay}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle> 
        <div style={{marginLeft:'-180px'}}><Heading title={title} closeIcon="true" onClose={onClose} /></div></DialogTitle>
      <DialogContent>{message}
      {contentToDisplay && (
          <div style={{ marginTop: '20px' }}>
            {contentToDisplay}
          </div>
      )}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button className='custom-button' onClick={onConfirm} color="primary">Confirm</Button>
        <Button className='custom-cancel-button' onClick={onClose}>Cancel</Button>
        
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationPopup;
