import React, { useState } from 'react';
import { Dialog, DialogContent, TextField, Button, Box,Typography } from '@mui/material';
import Heading from '../../Components/Heading/heading.tsx';
import { endTripApi } from './action.ts'; 
import Dropdown from '../../Components/DropDown/drop-down.tsx';
import { SelectChangeEvent } from '@mui/material/Select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface TripDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: 'start' | 'end';
  tripId: string;
  vehicleId: string;
  vehicleList: { id: string, number_plate: string }[];
}

const TripDetailsModal: React.FC<TripDetailsModalProps> = ({ isOpen, onClose, type, tripId, vehicleId, vehicleList }) => {
  const heading = type === 'start' ? 'Trip Details - Start' : 'Trip Details - End';
  const odometerLabel = type === 'start' ? 'Odometer Start' : 'Odometer End';
  const ordersLabel = type === 'start' ? 'Orders Picked' : 'Orders Delivered';
  const [odometerStart, setOdometerStart] = useState('');
  const [odometerEnd, setOdometerEnd] = useState('');
  const [ordersCreated, setOrdersCreated] = useState('');
  const [ordersDelivered, setOrdersDelivered] = useState('');
  const [selectedVehicle, setSelectedVehicle] = useState(vehicleId || '');
  const [odometerStartError, setOdometerStartError] = useState('');
  const [odometerEndError, setOdometerEndError] = useState('');
  const [ordersPickedUpError, setOrdersPickedUpError] = useState('');
  const [ordersDeliveredError, setOrdersDeliveredError] = useState('');
 const handleSave = () => {
  if (type === 'start' && !selectedVehicle) {
    toast.error("Please choose a vehicle");
    return;
  }
  if (type === 'start') {
    if (odometerStartError || ordersPickedUpError) {
      toast.error("Please fix the errors");
      return;
    }
  } else if (type === 'end') {
    if (odometerEndError || ordersDeliveredError) {
      toast.error("Please fix the errors");
      return;
    }
  }
    const tripIdToUse = type === 'start' ? '' : tripId;
    const actionType = type === 'start' ? 'start' : 'end';
    endTripApi(
      12.834932,
      77.606037,
      tripIdToUse,
      vehicleId || selectedVehicle,
      (response: any) => {
        if (response.data.status === 201 || response.data.status === 200) {
          toast.success(response.data.message.message);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          onClose();
        } else if (response.data.status === 400) {
          toast.error(response.data.message?.message || response.data.message);
        }
      },
      (errMessage: any) => {
        if (errMessage.status === 404) {
          toast.error(errMessage.message.errors);
        }
      },
      actionType,
      '',
      odometerStart,
      odometerEnd,
      ordersCreated,
      ordersDelivered
    ); 
  };
  const handleVehicleChange = (event: SelectChangeEvent<string>) => {
    setSelectedVehicle(event.target.value);
  };
  const handleOdometerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const decimalRegex = /^\d*\.?\d*$/;
    if (!decimalRegex.test(value)) {
      if (type === 'start'){
        setOdometerStartError('Invalid Input');
      }
      else if (type ==='end'){
        setOdometerEndError('Invalid Input')
      }
    } else {
      if (type === 'start'){
       setOdometerStartError('');
      }
      else {
        setOdometerEndError('');
      }
    }
    if (type === 'start') {
      setOdometerStart(value);
    } else {
      setOdometerEnd(value);
    }
  };

  const handleOrdersChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/[^0-9]/.test(value)) {
      if (type === 'start'){
        setOrdersPickedUpError('Invalid Input');
      }
      else if (type === 'end'){
        setOrdersDeliveredError('Invalid Input');
      }
    } else {
      if (type === 'start'){
      setOrdersPickedUpError('');
      }
      else {
      setOrdersDelivered('');
      }
    }
    if (type === 'start') {
      setOrdersCreated(value);
    } else {
      setOrdersDelivered(value);
    }
  };
  const handleCancel = () => {
    if(type === 'start'){
      setSelectedVehicle('');
      setOdometerStartError('');
      setOrdersPickedUpError('');
      setOdometerStart('');
      setOrdersCreated('');
    }
    else {
    setOdometerEnd('');
    setOrdersDelivered('');
    setOrdersDeliveredError('');
    setOdometerEndError('');
    }
    onClose();
  };
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Box sx={{ marginLeft: {
      sm: '-180px', 
    }, }}>
      <Heading title={heading} closeIcon={true} onClose={onClose} />
      </Box>
      <DialogContent
        sx={{
          minWidth: '200px',
          minHeight: '300px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflowY:'auto'
        }}
      >
        {type === 'start' && (
          <Dropdown
            options={vehicleList.map((vehicle) => ({
              id: vehicle.id,
              label: vehicle.number_plate,
            }))}
            selectedValue={selectedVehicle || ''}
            handleChange={(event) => handleVehicleChange(event)}
            placeholder="Choose a vehicle"
            label="Choose a vehicle"
            width='250px'
            height='50px'
            searchable={true}
            name='number_plate'
          />
        )}
        <TextField
          label={odometerLabel}
          fullWidth
          margin="normal"
          variant="outlined"
          type='text'
          value={type === 'start' ? odometerStart : odometerEnd}
          onChange={handleOdometerChange}
          sx={{ width: '250px' }}
        />
        {(odometerStartError || odometerEndError) && (
          <Typography color="error" sx={{ width: '250px', textAlign: 'left' }}>
             {type === 'start' ? odometerStartError : odometerEndError}
          </Typography>
        )}
        <TextField
          label={ordersLabel}
          fullWidth
          margin="normal"
          variant="outlined"
          type="text"
          value={type === 'start' ? ordersCreated : ordersDelivered}
          onChange={handleOrdersChange}
          sx={{ width: '250px' }}
        />
        {(ordersPickedUpError || ordersDeliveredError) && (
          <Typography color="error" sx={{ width: '300px', textAlign: 'left' }}>
            {type === 'start' ? ordersPickedUpError : ordersDeliveredError}
          </Typography>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
          <Button
            variant="contained"
            onClick={handleSave}
            sx={{
              backgroundColor: type === 'start' ? 'green' : 'red',
              color: 'white',
              '&:hover': {
                backgroundColor: type === 'start' ? 'darkgreen' : 'darkred',
              },
            }}
          >
            {type === 'start' ? 'Start Trip' : 'End Trip'}
          </Button>
          <Button variant="outlined" color="primary" onClick={handleCancel}>
            Cancel
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TripDetailsModal;
