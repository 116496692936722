import React,{useState, useEffect} from 'react';
import Dropdown from '../../Components/DropDown/drop-down.tsx';
import Newpagination from '../../Components/Pagination/pagination.tsx';
import '../../Components/Button/button-style.scss';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {SelectChangeEvent} from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import DescriptionIcon from '@mui/icons-material/Description'; 
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Button, TextField, Box} from '@mui/material';
import {getDrivers, editDriverLead,searchDriverLeads , getDriverName} from './action.ts';
import AddLeadModal from './add-lead-modal.tsx';
import {Select, MenuItem} from '@mui/material';
import EditDriverLeadModal from './edit-lead-modal.tsx';
import EditDriverModal from './edit-driver-modal.tsx';
import {validatePhoneNumber} from '../../Utils/PhoneNumberValidation/phone-number-validation.tsx';
import DocumentsModal from './driver-documents-modal.tsx';
import DeleteModal from './delete-modal.tsx';
import './driver_style.scss';

interface CommonTabsProps {
  tabName: string;
}

const CommonTabs: React.FC<CommonTabsProps> = ({ tabName}) => {
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [driversList, setDriversList] = useState<any>();
    const [searchTerm, setSearchTerm] = useState('');
    const [remarksList, setRemarksList] = useState<{ [key: number]: string }>({});
    const [driverId , setDriverId] = useState<number>(0);
    const [isAddLeadOpen, setIsAddLeadOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [leadId , setLeadId] = useState<number>(0);
    const [option,setOption] = useState<string>('');
    const [searchError, setSearchError] =  useState(false);
    const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
    const [shouldFetch, setShouldFetch] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [driverIdToDelete , setDriverIdToDelete] = useState<number>(0);
    const [remarksToPass, setRemarksToPass] = useState<string>('');
    const [statuses, setStatuses] = useState({});
    const handleClosePopUp = () => {
      setOpenDialog(false);
    };
    const statusOptions = [
      { id: 'blacklist', label: 'Blacklist'},
      { id: 'others', label: 'Others' },
      { id: 'recruited', label: 'Recruited' },
      { id: 'uninterested', label: 'Uninterested' },
      { id: 'lead', label: 'Lead' },
    ];
    const showEntries = [
          {id:'10',  label:'10'},
          { id: '20', label: '20' },
          { id: '50', label: '50' },
          { id: '100', label: '100' },
    ];
    const [selectedData, setSelectedData] = useState({
          name: '',
          pincode: '',
          phone: '',
          whatsapp_number: '',
          email: '',
          remarks:'',
          status : '',
          location: '',
    });
    const updateField = (event: SelectChangeEvent<string>) => {
          const value = event.target.value;
          if (!isNaN(Number(value))) {
              setPageSize(Number(value));
              setPage(0);
          }
    };
    useEffect(() => {
      setPage(0);
      setPageSize(10);
      setTotalRecords(0);
      setShouldFetch(false); 
      setSearchTerm('');
    }, [tabName]);
    useEffect(() => {
      if (!shouldFetch) {
        setShouldFetch(true);
        return; 
      }
      let leadValue: string | null = null;
      let blacklistValue: string | null = null;
      let leftValue: string | null = null;
        if (tabName === 'driver') {
          leadValue = null;
          blacklistValue = null;
          leftValue = null;
        } else if (tabName === 'left') {
          leftValue = '1';
          leadValue = null;
          blacklistValue = null;
        } else if (tabName === 'blacklist') {
          blacklistValue = '1';
          leftValue = null;
          leadValue = null;
        } else if (tabName === 'lead') {
          leadValue = '1';
          blacklistValue = null;
          leftValue = null;
        }
        fetchUsers(page, pageSize, leadValue, blacklistValue, leftValue, tabName);
    }, [page,pageSize,shouldFetch]);
    const fetchUsers = (page, pageSize, lead, blacklist, left, tabName) => {
      setDriversList([]);
      setRemarksList({});
      getDrivers(
          lead,
          blacklist,
          pageSize,
          page,
          left,
          (response: any) => {
            let driverDetails = [];
            let totalRecords = 0;
            if (tabName === 'left') {
              driverDetails = Array.isArray(response.left_drivers)
                ? response.left_drivers.map((driver: any) => {
                    const driverInfo = driver.driver_user || driver.driver_lead;
                    return {
                      id: driver.id,
                      name: driverInfo?.name,
                      phone: driverInfo?.phone,
                      whatsapp_number: driverInfo?.whatsapp_number,
                      email: driverInfo?.email,
                      remarks: driver.remarks,
                    };
                  })
                : [];
              totalRecords = response.total_count|| 0;
            } 
            else if (tabName === 'blacklist') {
              driverDetails = Array.isArray(response.blacklisted_drivers)
                ? response.blacklisted_drivers.map((driver: any) => {
                    const { driver_user, driver_lead } = driver;
                    return {
                      id: driver.id,
                      name: driver_user?.name || driver_lead?.name,
                      phone: driver_user?.phone || driver_lead?.phone,
                      email: driver_user?.email || driver_lead?.email,
                      remarks: driver.remarks,
                    };
                  })
                : [];
              totalRecords = response.total_count|| 0;
            } 
            else if (tabName === 'driver') {
              toast.success(response);
              driverDetails = response.drivers || [];
              totalRecords = response.total_count|| 0;
            } 
            else if (tabName === 'lead') {
              driverDetails = response.driver_leads || [];
              totalRecords = response.total_count|| 0;
            }
            setDriversList(driverDetails);
            const initialRemarksList = driverDetails.reduce((acc, driver) => {
              acc[driver.id] = driver.remarks || '';
              return acc;
            }, {});
            setRemarksList(initialRemarksList);
            setTotalCount(Math.ceil(totalRecords / pageSize));
            setTotalRecords(totalRecords);
            if (driverDetails.length === 0) {
              totalRecords = 0;
              setTotalRecords(0); 
            }
          },
          (errMessage: any) => {
            toast.error(errMessage);   
          }
         );
    };
    const handleInputChange = (event) => {
      const { value } = event.target;
       setSearchTerm(value);
        if(value){
            const { errorMessage } = validatePhoneNumber(value);
            if(errorMessage){
                toast.error(errorMessage);
                setSearchError(true);
            }
           else{
                setSearchError(false);
            }
        }
       else {
           if(tabName==='driver')
              {
                  fetchUsers(page, pageSize, null, null, null, 'driver');
              }
           if(tabName==='left')
              {
                  fetchUsers(page, pageSize, null, null, '1', 'left');
              }
           if(tabName==='blacklist')
             {
                  fetchUsers(page, pageSize, null, '1', null, 'blacklist');
             }
           if(tabName==='lead')
            {
                  fetchUsers(page, pageSize, '1', null, null, 'lead');
            }
       }
    };
    const handleSearchClick = () => {
        if (searchTerm.length>=1&&searchTerm.length<10) {
           toast.error('Please enter a 10 digit number');
           setSearchError(true);
           return;
        } 
        if(!searchError){
          if(tabName==='lead'){
           searchDriverLeads(
              searchTerm,
              (response: any) => {
                 const driverDetails = response.message || [];
                 setDriversList(driverDetails);
              },
             (error) => {
               toast.error(error.message);
               setDriversList([]);
              }
           );
          }
          else if (tabName==='driver'){
              getDriverName(
                      searchTerm,
                          (response) => {
                              const user = response.message.user[0];
                              const driverData = {
                                  id: user.user_unique_id,
                                  name: user.user_name,
                                  email: user.user_email,
                                  phone: user.user_phone,
                                  whatsapp_number: user.whatsapp_number,
                               };
                              setDriversList([driverData]); 
                          },
                          (error) => {
                              toast.error("No driver found for the number");
                              setDriversList([]);
                          }
             );
          }
        }
    };
    const handleEditClick = (rowData: any,id ,option:string) => {
      if(tabName==='lead'){
          setSelectedData(rowData);
          setOpenModal(true);
          setLeadId(id);
          setOption(option);}
      else if(tabName==='driver'){
          setSelectedData(rowData);
          setOpenModal(true);
          setDriverId(id);
      }
    }; 
    const handleClickOpen = () => {
      setIsAddLeadOpen(true);
    };
    const handleCloseAddLead = () => {
      setIsAddLeadOpen(false);
    };
    const handleCloseModal = () => {
          setOpenModal(false);
    };
    const handleDeleteClick = (rowData: any,id ,remarks) => {
        if(tabName==='lead'){
            handleEditClick(rowData,id ,"delete");}
        else if(tabName==='driver'||tabName==='blacklist'||tabName==='left')
            {
               setDriverIdToDelete(id);
               setRemarksToPass(remarks);
               setOpenDialog(true);
            }
    };
   const handleClose = () => {
        setIsDocumentModalOpen(false);
    };
    const handleRemarksInput = (e, driverId) => {
          setRemarksList((prevRemarksList) => ({
             ...prevRemarksList,
             [driverId]: e.target.value, 
          }));
    };
    const handleRemarksChange = (value: string, rowId: number, field: string) => {
          console.log(`Updating remarks for row ${rowId}:`, value);
    };
    const handleDocumentsClick = (id) => {
           setIsDocumentModalOpen(true);
           setDriverId(id);
    };
    const columns: GridColDef[] = [
          { field: 'sl_no', headerName: 'Sl No', width: 70, headerAlign: 'center' },
          { field: 'name', headerName: 'Name',   width:200, headerAlign: 'center' },
          { field: 'location', headerName: 'Location',   width:160, headerAlign: 'center' },
          { field: 'phone', headerName: 'Mobile Number',   width:200, headerAlign: 'center' },
          { field: 'whatsapp_number', headerName: 'WhatsApp Number',   width:200, headerAlign: 'center' },
          { field: 'email', headerName: 'Email',   width:200, headerAlign: 'center' },
          {
            field: 'remarks',
            headerName: 'Remarks',
           width:140,
            headerAlign: 'center',
            editable: true, 
            renderCell: (params) => {
              return (
                <div
                  style={{
                    border: '1px solid grey',
                    borderRadius: '5px',
                    outline: 'none',
                    boxShadow: 'none',
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box',
                  }}
                >
                  {params.row.remarks || ''} 
                </div>
              );
            },
            renderEditCell: (params: GridRenderCellParams) => (
              <input
                type="text"
                value={remarksList[params.row.id] ?? ''} 
                onChange={(e) => handleRemarksInput(e, params.row.id)} 
                onBlur={() => handleRemarksChange(remarksList[params.row.id], params.row.id, 'remarks')} 
                style={{
                  border: '1px solid black',
                  outline: 'none',
                  boxShadow: 'none',
                  width: '100%',
                  height: '100%',
                }}
              />
            ),
          },
          {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            headerAlign: 'center',
            renderCell: (params: GridRenderCellParams) => {
              return (
                <div
                  className={
                     tabName === 'driver' ? 'driver-icons-wrapper'
                     : tabName === 'lead' ? 'lead-icons-wrapper'
                     : 'general-icons-wrapper'
                  } 
                >
                  {tabName === 'driver' && (
                    <>
                      <IconButton color="primary" onClick={() => handleDocumentsClick(params.row.id)} sx={{ padding: '0px' }}>
                        <DescriptionIcon />
                      </IconButton>
                      <IconButton color="primary" onClick={() => handleEditClick(params.row,params.row.id,"")} sx={{ padding: '0px' }}>
                        <EditIcon />
                      </IconButton>
                      <IconButton color="primary" onClick={() => handleDeleteClick("",params.row.id, params.row.remarks)} sx={{ padding: '0px' }}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                  {tabName === 'lead' && (
                    <>
                      <IconButton color="primary" onClick={() => handleEditClick(params.row,params.row.id,"edit")} sx={{ padding: '0px' }}>
                        <EditIcon />
                      </IconButton>
                      <IconButton color="primary" onClick={() => handleDeleteClick(params.row,params.row.id,"")} sx={{ padding: '0px' }} >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                  {(tabName === 'blacklist' || tabName === 'left') && (
                    <IconButton color="primary" onClick={() => handleDeleteClick("" ,params.row.id,"")}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
              );
            },
          },
        ];
      if (tabName === 'lead') {
        const leadStatusColumn = {
          field: 'status',
          headerName: 'Status',
          flex: 1,
          headerAlign: 'center',
          renderCell: (params) => {
              const { id, status } = params.row;
              const handleStatusChange = (event, id) => {
                  const updatedStatus = event.target.value;
                  setStatuses((prevStatuses) => ({
                       ...prevStatuses,
                       [id]: updatedStatus,
                  }));
                   editDriverLead(
                       id,
                       '', 
                       '', 
                       '', 
                       '',
                       updatedStatus,
                       '',
                       (response) => {
                           toast.success(response.message); 
                           fetchUsers(page, pageSize, '1', null, null, 'lead');
                        },
                        (errorMessage) => {
                            toast.error(errorMessage);
                        }
                    );
               };
              const currentStatus = statuses[id] || status || 'lead';
                return (
                  <Select
                    value={currentStatus}
                    onChange={(event) => handleStatusChange(event, id)} 
                    fullWidth
                  >
                     {statusOptions.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                           {option.label}
                        </MenuItem>
                     ))}
                  </Select>
               );
          },
        };
        columns.splice(6, 0, leadStatusColumn);
    }
    const handlePageClick = (event: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage - 1);
    };
    const rows = Array.isArray(driversList) ? driversList.map((driver, index) => ({
        id: driver.id,
        sl_no: (page * pageSize) + index + 1,
        name: driver.name,
        phone: driver.phone,
        whatsapp_number: driver.whatsapp_number,
        email : driver.email,
        pincode : driver.pincode,
        remarks : driver.remarks,
        status : driver.status,
    })) : [];
    return (
        <>
          <div style={{ display: "flex", alignItems: "center", marginTop: "-20px", gap: "5px", whiteSpace: "nowrap" }}>
           <span>Show Entries:</span>
           <Dropdown
                 options={showEntries}
                 selectedValue={pageSize.toString()}
                 handleChange={(event) => updateField(event)}
                 width="80px"
                height="30px"
            />
            <Box  className="styled-textfield"
                  display="flex" 
                  justifyContent="space-between"  
                  alignItems="center" 
                  style={{ margin: '40px 0', width: '100%' }}  
                >
                 <Box 
                   display="flex" 
                   justifyContent="center"  
                   alignItems="center"
                   style={{ flexGrow: 1 }}  
                 >
                    <TextField
                        className="styled-textfield"
                        variant="outlined"
                        size="small"
                        value={searchTerm}
                        onChange={handleInputChange}
                        placeholder="Search by Mobile Number"
                    />   
                    <Button  className='custom-button' onClick={handleSearchClick} style={{ marginLeft: '13px' }}> 
                         Search
                    </Button>
                  </Box>
            </Box>
            {tabName === 'lead' && (
                  <>
                   <Button 
                      className='custom-button' 
                      onClick={handleClickOpen} 
                      style={{ width: '110px', height: '40px' }}  
                    >
                       Add Leads
                    </Button>
                   <AddLeadModal addLeadModalOpen={isAddLeadOpen} onClose={handleCloseAddLead} fetchUsers={fetchUsers} page={page} pageSize={pageSize} />   
                   </>  
                )}
          </div>
          <div className="table-pagination-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',height: '53vh', }}>
                <Box className="table-container" style={{  marginLeft: {
      xs: '10px', 
      sm: '10px', 
    },position: 'relative', overflowY: 'auto', flexGrow: 1, width: '100%', marginTop: '-25px' }}>
                     <DataGrid
                         rows={rows}
                         columns={columns}
                         rowCount={totalRecords}
                         autoHeight ={false}
                         disableRowSelectionOnClick
                         disableColumnResize
                         hideFooterPagination
                         classes={{
                            columnHeader: 'red-header',
                         }}
                      />
                </Box>
                <div className="pagination-container" style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '10px' }}>
                     <Newpagination
                        rows={totalRecords}
                        pageSize={pageSize}
                        page={page}
                        handlePageChange={handlePageClick}
                      />
                </div>
            </div>
               {tabName === 'driver' && (
                  <EditDriverModal
                       open={openModal}
                       onClose={handleCloseModal}
                       driverData={selectedData}
                       driverId={driverId}
                       fetchUsers={fetchUsers} page={page} pageSize={pageSize}
                  />
             )}
            {tabName === 'lead' && (
                <EditDriverLeadModal
                    open={openModal}
                    onClose={handleCloseModal}
                    driverData={selectedData}
                    leadId={leadId}
                    option={option}
                    fetchUsers={fetchUsers} page={page} pageSize={pageSize}
                />
            )} 
           {(tabName === 'driver' || tabName === 'left' || tabName === 'blacklist')  && (
               <DeleteModal open={openDialog} handleClose={handleClosePopUp} driverId={driverIdToDelete} remarksToPass={remarksToPass} option={tabName} fetchUsers={fetchUsers} page={page} pageSize={pageSize}/>
           )}
           <DocumentsModal open={isDocumentModalOpen} onClose={handleClose} driverId={driverId}/> 
        </>
    );
};
export default CommonTabs;
 
