import React,{useState,useRef, useEffect} from 'react';
import NavBar from '../../Components/Navbar/sidenav.tsx';
import Header from '../../Components/Header/header.tsx';
import Heading from '../../Components/Heading/heading.tsx';
import Dropdown from '../../Components/DropDown/drop-down.tsx';
import Newpagination from '../../Components/Pagination/pagination.tsx';
import EditVehicleModal from './edit-vehicle-modal.tsx';
import AddVehicleModal from './add-vehicle-modal.tsx';
import DocumentsModal from './documents-modal.tsx';
import '../../Components/Button/button-style.scss';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { SelectChangeEvent } from '@mui/material/Select';
import { getVehicle,getClients} from '../Vehicles/action.ts';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import DescriptionIcon from '@mui/icons-material/Description'; 
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {  Button ,TextField, Box, Typography } from '@mui/material';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
interface VehiclesProps {   
}
const Vehicles: React.FC<VehiclesProps> = (props) => {
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [vehicleList, setVehicleList] = useState<any>();
    const [selectedVehicle, setSelectedVehicle] = useState<any>(null);
    const [clientOptions,setClientOptions]= useState<{id: string , label: string}[]>([]);
    const [selectedClient, setSelectedClient] = useState<{ id: string, name: string } | null>(null);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [openModal, setOpenModal] = useState(false);
    const [documentsModal, setDocumentsModal] = useState(false);
    const [isAddVehicleOpen, setIsAddVehicleOpen] = useState(false);
    const exportToExcel = () => {
      const worksheet = XLSX.utils.json_to_sheet(vehicleList.map(vehicle => ({
        'Sl No': (page * pageSize) + vehicleList.indexOf(vehicle) + 1,
        'Vehicle Number': vehicle?.number_plate,
        'Model': vehicle?.make_model,
        'Make Year': vehicle?.year,
        'Owner': vehicle?.owner_name,
        'Driver': vehicle?.driver_name,
        'Fuel Type':vehicle?.fuel_type
      })));
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Vehicles');
      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'vehicles_list.xlsx');
    };
    const updateField = (event: SelectChangeEvent<string>) => {
      const value = event.target.value;
    if (!isNaN(Number(value))) {
      setPageSize(Number(value));
      setPage(0);
    }
  };
    const handleCloseModal = () => {
      setOpenModal(false);
      setSelectedVehicle(null);
    };
    const handleClickOpen = () => {
      setIsAddVehicleOpen(true);
    };
    useEffect(() => {
      getVehicle(
        pageSize,
        page,
        (response: any) => {
          const vehicleDetails = response.message?.vehicles_list || [];
          setVehicleList(vehicleDetails);
          const totalVehiclesCount = response.message.total_vehicles_count;
          setTotalRecords(totalVehiclesCount);
        },
        (errMessage: any) => {
            toast.error(errMessage.message);
            setVehicleList('');
            setTotalRecords('');
        },
        '',
        selectedClient?.name
    );
  }, [page,pageSize,selectedClient]); 
  useEffect (() => { 
    getClients(
    (response:any) => {
      const options = response.message.clients.map ((clients:any) => ({
        id:clients.id,
        label:clients.name,
      }));
      setClientOptions(options);
    },
    (errMessage:any) => {
      toast.error(errMessage);
    }
  );},[])
      const showEntries = [
        {id:'10',  label:'10'},
        { id: '20', label: '20' },
        { id: '50', label: '50' },
        { id: '100', label: '100' },
      ];
      const handlePageClick = (event: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage - 1);
      };
      const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
       };
       const handleSearchClick = () => {
        getVehicle(
          '',
          '',
          (response: any) => {
            const vehicleDetails = response.message?.vehicles_list || [];
            setVehicleList(vehicleDetails);
            const totalVehiclesCount = response.message.total_vehicles_count;
            setTotalRecords(totalVehiclesCount);
          },
          (errMessage: any) => {
            if(errMessage.message?.errors?.number_plate){
              toast.error(errMessage.message?.errors?.number_plate[0])
            }
            else
              {toast.error(errMessage.message);}
              setVehicleList('')
              setTotalRecords(0);
          },
          searchTerm,
          selectedClient?.name,
      );
      };
      const handleCloseDetailsModal = () => {
        setDocumentsModal(false);
        setSelectedVehicle(null);
      };
      const handleDocumentsClick = (vehicle: any) => {
        setSelectedVehicle(vehicle);
        setDocumentsModal(true);
      };
      const handleEditClick = (vehicle: any) => {
        setSelectedVehicle(vehicle);
        setOpenModal(true);
      };
      const columns: GridColDef[] = [
        { field: 'sl_no', headerName: 'Sl No',   width:100,headerAlign:'center' },
        {field: 'vehicle_no',headerName: 'Vehicle Number',width:165, headerAlign:'center'},
        { field: 'driver', headerName: 'Driver',  width:200,headerAlign:'center'},
        { field: 'owner', headerName: 'Owner ',  width:200,headerAlign:'center'},
        { field: 'model', headerName: 'Model',   width:200, headerAlign:'center'},
        { field: 'make_year', headerName: 'Make Year',   width:140,headerAlign:'center' },
        {field: 'fuel_type', headerName: 'Fuel Type',  width:140,headerAlign:'center'},
       
       
        {
          field: 'actions',
          headerName: 'Actions',
          width: 150, headerAlign:'center',
          renderCell: (params: GridRenderCellParams) => {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  gap: '8px',
                }}
              >
              <IconButton color="primary" onClick={() => handleDocumentsClick(params.row)} >
              <DescriptionIcon /> 
            </IconButton>
            <IconButton color="primary" onClick={() => handleEditClick(params.row)}>
              <EditIcon />
            </IconButton>
          </div>
            );
          },
        }
      ];
      const handleCloseAddVehicle = () => {
        setIsAddVehicleOpen(false);
      };
      const handleClientChange = (event: SelectChangeEvent<string>) => {
        const clientId = event.target.value;
        const client = clientOptions.find(c => c.id === clientId);
        const transformedClient = client ? { id: client.id, name: client.label } : null;
        setSelectedClient(transformedClient);
      }
      const rows = vehicleList ? vehicleList.map((vehicle, index) => (
        {
            id: vehicle.id,
            sl_no: (page * pageSize) + index + 1,
            vehicle_no: vehicle?.number_plate,
            model: vehicle?.make_model,
            make_year: vehicle?.year,
            owner: vehicle?.owner_name,
            driver: vehicle?.driver_name,
            driver_id: vehicle?.driver_id,
            fuel_type:vehicle?.fuel_type
        }
    )) : [];
   return (
    <>   
      <Header/>
      <div style={{ marginTop:"70px"}}>
      <Heading
  title="List Of Vehicles"
  page='vehicles'
  closeIcon="false"
  leftContent={
    <>
      <Box display="flex" alignItems="center">
        <Typography style={{ marginRight: '5px' }}>Show Entries:</Typography>
        <Dropdown
          options={showEntries}
          selectedValue={pageSize.toString()}
          handleChange={(event) => updateField(event)}
          width="90px"
          height="50px"
        />
        </Box>
        <Box sx={{ marginLeft: '10px', marginTop: {
         xs: '10px',
         sm: '10px',
         md:'10px',
         lg:'0px'} }}>
          <Dropdown
            options={clientOptions}
            selectedValue={selectedClient?.id || ''}
            handleChange={(event) => handleClientChange(event)}
            placeholder="Choose Client"
            label={"Choose Client"}
            width="190px"
            height="50px"
          />
        </Box>

    </>
  }
  rightContent={
    <>
      <Box display="flex" alignItems="center" gap="10px">
        <TextField
          className="styled-textfield"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleInputChange}
          placeholder="Search by Vehicle Number"
        />
        <Button className="custom-button" onClick={handleSearchClick}>
          Search
        </Button>
        </Box>
        <Box  sx={{ marginTop: {
      xs: '10px', 
      sm: '0px',  md:'10px', lg:'0px'} }}>
        <Button className="custom-button" onClick={handleClickOpen}>
          Add Vehicle
        </Button>
        <Button className="custom-button" sx={{ marginLeft: {
      xs: '10px', 
      sm: '0px',  md:'10px'} }} onClick={exportToExcel}>
          <DownloadIcon />
        </Button>
      </Box>
    </>
  }
/>
<AddVehicleModal
  addVehicleModalOpen={isAddVehicleOpen}
  handleCloseAddVehicle={handleCloseAddVehicle}
/>
          </div>
          <Box className="table-container custom-data-grid" sx={{ marginLeft: {
      xs: '10px', 
      sm: '180px', 
    },marginTop: '10px', marginRight: '40px', position: 'relative', height: '500px', overflowY: 'auto' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    rowCount={totalRecords}
                    autoHeight={false}
                    disableRowSelectionOnClick
                    disableColumnResize
                    hideFooterPagination
                    classes={{
                        columnHeader: 'red-header',
                    }}
                />
            </Box>
        <div style={{marginLeft : '50%' , marginTop : '8px'}}> 
    <Newpagination
          rows={totalRecords}
          pageSize={pageSize}
          page={page}
          handlePageChange={handlePageClick}
        />
  </div>
      <NavBar selected='Vehicles' />
      <EditVehicleModal
      open={openModal}
      onClose={handleCloseModal}
      vehicle={selectedVehicle}
    />
  <DocumentsModal
     open={documentsModal}
     onClose={handleCloseDetailsModal}
     vehicle={selectedVehicle}
    />
    </>
    );
};
export default Vehicles;