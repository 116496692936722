import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, IconButton, Tooltip,Box,SelectChangeEvent } from '@mui/material';
import { getDocuments, addDocuments,editDocuments, editVehicle,getDriverName,deleteDocument,fetchClients,fetchVendors,addClientUserMapping, getClientName,addVendorUserMapping} from './action.ts';
import ConfirmationPopup from '../../Components/ConfirmationModal/confirmation-modal.tsx';
import Heading from '../../Components/Heading/heading.tsx';
import UploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import '../../Components/Table/table-style.scss';
import DatePicker from '../../Components/DatePicker/date-picker.tsx';
import Dropdown from '../../Components/DropDown/drop-down.tsx';
import './vehicle.scss';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface EditVehicleModalProps {
  open: boolean;
  onClose: () => void;
  vehicle: {
    id: any;
    vehicle_no: string;
    model: string;
    make_year: string;
    owner: string;
    driver: string;
    driver_id:number;
    fuel_type: string;
  } | null;
}
interface Client {
  id: number;
  name: string;
}
interface Vendor {
  id: number;
  name: string; 
}

const EditVehicleModal: React.FC<EditVehicleModalProps> = ({ open, onClose, vehicle }) => {
  const [formValues, setFormValues] = useState({
    vehicle_no: '',
    model: '',
    make_year: '',
    owner: '',
    driver: '',
  });
  const [initialValues, setInitialValues] = useState(formValues);
  const [selectedInsuranceDate, setSelectedInsuranceDate] = useState<string>('');
  const [selectedFitnessDate, setSelectedFitnessDate] = useState<any>('');
  const [initialInsuranceDate, setInitialInsuranceDate] = useState<any>('');
  const [initialFitnessDate, setInitialFitnessDate] = useState<any>('');
  const [getFitnessDoc, setGetFitnessDoc] = useState<string>('');
  const [getInsuranceDoc, setGetInsuranceDoc] = useState<string>('');
  const [documentList, setDocumentList] = useState<any[]>([]);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [documentToRemove, setDocumentToRemove] = useState<string | null>(null);
  const [ownerUserName, setOwnerUserName] = useState<string>('');
  const [driverUserName, setDriverUserName] = useState<string>('');
  const [ownerPhoneNumber, setOwnerPhoneNumber] = useState<string>('');
  const [driverPhoneNumber, setDriverPhoneNumber] = useState<string>('');
  const [documentDeleted, setDocumentDeleted] = useState(false);
  const [ownerId, setOwnerId] = useState<string>('');
  const [driverId, setDriverId] = useState<string>('');
  const [isOwnerFocused, setIsOwnerFocused] = useState<boolean>(false);
  const [isDriverFocused, setIsDriverFocused] = useState<boolean>(false);
  const [ownerError, setOwnerError] = useState<string | null>(null);
  const [driverError, setDriverError] = useState<string | null>(null);
  const [makeYearError, setMakeYearError] = useState<string | null>(null);
  const [modelError, setModelError] = useState<string | null>(null);
  const [clientError, setClientError] = useState<string | null>(null);
  const [documentLocation, setDocumentLocation] = useState<string | null>(null);
  const [documentId, setDocumentId] = useState<number | null>(null);
  const [getInsuranceDate, setGetInsuranceDate] = useState<string | null>(null);
  const [getFitnessDate, setGetFitnessDate] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedClient, setSelectedClient] = useState<{ id: string; label: string }[]>([]);
  const [fuelType, setFuelType] = useState<{ id: string; label: string }[]>([]);
  const [fuelName, setFuelName] = useState<string>('');
  const [selectedVendor, setSelectedVendor] = useState<{ id: string; label: string }[]>([]);
  const [clientData,setClientData] = useState<string | null>(null);
  const [clients, setClients] = useState<Client[]>([]);
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const storedUserDetails = localStorage.getItem("userdetails");
  const userDetails = storedUserDetails ? JSON.parse(storedUserDetails) : null;
  const userRole = userDetails ? userDetails.role : null
  const vendorName = userDetails? userDetails.vendor_name : null;
  const clientName = userDetails? userDetails.client_name : null;
  const vendorId = storedUserDetails ? JSON.parse(storedUserDetails).vendor_id:0;

  const resetErrorMessages = () => {
    setOwnerError(null);
    setDriverError(null);
    setMakeYearError(null);
    setModelError(null);
    setClientError(null);
    setErrorMessage(null);
    setSelectedInsuranceDate('');
    setGetFitnessDate('');
    setGetInsuranceDate('');
    setSelectedFitnessDate('');
    setSelectedClient([]);
    setClientData('');
  };
  const handleClose = () => {
    resetErrorMessages();
    onClose();
  };
  const handleOwnerFocus = () => {
    setIsOwnerFocused(true);
    if(ownerPhoneNumber.length === 10){
    fetchDriverDetails(ownerPhoneNumber, 'owner');
    }
    setOwnerUserName('');
  };
  const handleOwnerBlur = () => {
    setIsOwnerFocused(false);
  };
  const handleMakeYearBlur = () => {
    if (formValues.make_year.length === 0) {
      setMakeYearError(''); 
      return;
    }
    if (formValues.make_year.length < 4){
      setMakeYearError('Year must be a 4 digit number')
      return;
    }
  }
  const handleDriverFocus = () => {
    setIsDriverFocused(true);
    if(driverPhoneNumber.length === 10 ){
    fetchDriverDetails(driverPhoneNumber, 'driver');}
    setDriverUserName('');
  };
  const handleDriverBlur = () => {
    setIsDriverFocused(false);
  };
  const fuelTypeOptions = [
    { id: 'diesel', label: 'Diesel' },
    { id: 'EV', label: 'EV' },
    { id: 'petrol', label: 'Petrol' },
    { id: 'CNG', label: 'CNG' }
  ];
  const [selectedFiles, setSelectedFiles] = useState<{ [key: string]: File | null }>({
    registrationCertificate: null,
    pollutionCertificate: null,
    fitnessCertificate: null,
    insurance: null,
    drivers_license: null,
    aadhar: null
  });
  const [icons, setIcons] = useState<{ [key: string]: string }>({
    registrationCertificate: 'upload',
    pollutionCertificate: 'upload',
    fitnessCertificate: 'upload',
    insurance: 'upload'
  });
  const fetchDocuments = () => {
    if (vehicle?.id) {
      getDocuments(
        vehicle.id,
        (response: any) => {
          const documentDetails = response.message?.vehicle_documents || [];
          setDocumentList(documentDetails);
          const updatedDocuments = documentDetails.map(doc => ({
            ...doc,
            expiry: doc.status === 'uploaded' ? doc.expiry || '' : ''
          }));
          const insuranceDoc = updatedDocuments.find(doc => 
            doc.document_name.trim() === 'insurance' && doc.status === 'uploaded'
          );
          const fitnessDoc = updatedDocuments.find(doc => 
            doc.document_name.trim() === 'fitness certificate' && doc.status === 'uploaded'
          );
        if (insuranceDoc) {
          setGetInsuranceDate(insuranceDoc.expiry);
          setInitialInsuranceDate(insuranceDoc.expiry);
          setGetInsuranceDoc(insuranceDoc);
        }
        if (fitnessDoc) {
          setGetFitnessDate(fitnessDoc.expiry);
          setInitialFitnessDate(fitnessDoc.expiry);
          setGetFitnessDoc(fitnessDoc);
        }
          const newIcons: { [key: string]: string } = {
            registrationcertificate: 'upload',
            pollutioncertificate: 'upload',
            fitnesscertificate: 'upload',
            insurance: 'upload'
          };
          documentDetails.forEach(doc => {
            if (doc.status === 'uploaded') {
              newIcons[doc.document_name.replace(' ', '')] = 'view';
            }
          });
          setIcons(newIcons);
        },
        (errMessage: any) => {
          toast.error(errMessage);
        }
      );
    }
  };
  useEffect(() => {
    if (open && vehicle?.id) {
        fetchDocuments();
      }
    if (vehicle) {
      setFormValues({
        vehicle_no: vehicle.vehicle_no || '',
        model: vehicle.model || '',
        make_year: vehicle.make_year || '',
        owner: vehicle.owner || '',
        driver: vehicle.driver || '',
      });
      setInitialValues({
        vehicle_no: vehicle.vehicle_no || '',
        model: vehicle.model || '',
        make_year: vehicle.make_year || '',
        owner: vehicle.owner || '',
        driver: vehicle.driver || '',
      })
        if(vehicle?.driver_id){
          getClientName(
            vehicle?.driver_id,
            (response) => {setClientData(response.message[0].clients_name);
            },
            (errorMessage) => {toast.error(errorMessage);}
          )
        }
      setOwnerUserName(vehicle.owner || '');
      setDriverUserName(vehicle.driver || '');
    }
    if(vendorName !== '' && vehicle){
      fetchClients(
      (response) => {setClients(response);
      },
      (errorMessage) => toast.error(errorMessage)
       );
     }
      else if (clientName !== '' && vehicle){
       fetchVendors(
         (response) => setVendors(response),
         (errorMessage) => toast.error(errorMessage)
     );
      } 
  }, [vehicle]);
  useEffect(() => {
    if (vehicle?.fuel_type) {
      const matchedOption = fuelTypeOptions.find(option => option.id === vehicle.fuel_type);
      if (matchedOption) {
        setFuelType([{ id: matchedOption.id.toString(), label: matchedOption.label }]);
      }
    }
  }, [vehicle?.fuel_type]);
  useEffect(() => {
    if (clientData) {
      const matchedClient = clients.find(client => client.name === clientData);
      if (matchedClient) {
        setSelectedClient([{ id: matchedClient.id.toString(), label: matchedClient.name }]);
      }
    }
  }, [clientData, clients]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    if (name === 'model') {
      const error = validateModelName(value);
      setModelError(error); 
    }else if (name === 'make_year') {
      validateMakeYear(value);
    }
  };
  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target; 
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    validatePhoneNumber(value, name);
    if (name === 'driver') {
      setDriverPhoneNumber(value);
      if (value.length === 10) {
        fetchDriverDetails(value, 'driver');
      }
    } else {
      setOwnerPhoneNumber(value);
        if(value.length === 10){
          fetchDriverDetails(value, 'owner');
        }
    }
  };
  const validateModelName = (value: string) => {
    const regex = /^[a-zA-Z0-9\s]*$/;
    if (!regex.test(value)) {
      return 'Invalid Model Name.';
    }
    return null;
  };
  const uploadFile = (type: string, file: File, insuranceDate: string , fitnessCerificateDate: string,action:string) => {
    let insuranceExpiryDate = '';
    let fitnessExpiryDate = '';
    if (type === 'insurance') {
      insuranceExpiryDate = insuranceDate; 
    } else if (type === 'fitnesscertificate') {
      fitnessExpiryDate = fitnessCerificateDate; 
    }
    if(action === 'add'){
    addDocuments(
        type === 'drivers_license' ? file : null,
        type === 'aadhar' ? file : null,
        type === 'registrationcertificate' ? file : null,
        type === 'insurance' ? file : null,
        type === 'fitnesscertificate' ? file : null,
        type === 'pollutioncertificate' ? file : null,
        vehicle?.id,
        fitnessExpiryDate,
        insuranceExpiryDate,
        (response) => {
            if (response.status === 200) {           
                setSelectedFiles((prevFiles) => ({
                  ...prevFiles,
                  [type]: file                 
                }));
                setIcons((prevIcons) => ({
                  ...prevIcons,
                  [type]: 'view'
                }));
                toast.success("Document Uploaded Successfully");
                setErrorMessage(null);   
                setDocumentLocation(response?.message?.location);
                setDocumentId(response?.message?.id)
                }              
        },
        (errMessage: any) => {  
          if (errMessage.status === 400) {
            let allErrorMessages: string[] = [];
            if (typeof errMessage.message === 'string') {
              allErrorMessages.push(errMessage.message);
              setSelectedFiles((prevFiles) => ({
                ...prevFiles,
                [type]: file
              }));
              setIcons((prevIcons) => ({
                ...prevIcons,
                [type]: 'view'
              }));
              toast.error(allErrorMessages.join(" "));
            } 
            else if (errMessage.status =413 ){
              toast.error("Document Size is large");
            }
            else if (errMessage.message && typeof errMessage.message === 'object') {
              const errors = errMessage.message.errors || {};
              for (const [key, messages] of Object.entries(errors)) {
                if (Array.isArray(messages)) {
                  allErrorMessages.push(...messages);
                }
              }
              setSelectedFiles((prevFiles) => ({
                ...prevFiles,
                [type]: null
              }));
              setIcons((prevIcons) => ({
                ...prevIcons,
                [type]: 'upload'
              }));
              toast.error(allErrorMessages.join(" "));
            }
            if (allErrorMessages.length > 0) {
              setErrorMessage(allErrorMessages.join(" "));
            } 
          } 
        });}
        else {
          editDocuments(
            type === 'drivers_license' ? file : null,
            type === 'aadhar' ? file : null,
            type === 'registrationcertificate' ? file : null,
            type === 'insurance' ? file : null,
            type === 'fitnesscertificate' ? file : null,
            type === 'pollutioncertificate' ? file : null,
            vehicle?.id,
            fitnessExpiryDate,
            insuranceExpiryDate,
            (response) => {
                if (response.status === 200) {           
                    setSelectedFiles((prevFiles) => ({
                      ...prevFiles,
                      [type]: file                 
                    }));
                    setIcons((prevIcons) => ({
                      ...prevIcons,
                      [type]: 'view'
                    }));
                    toast.success("Document Uploaded Successfully");
                    setErrorMessage(null);   
                 
                    }              
            },
            (errMessage: any) => {  
              if (errMessage.status === 400) {
                let allErrorMessages: string[] = [];
                if (typeof errMessage.message === 'string') {
                  allErrorMessages.push(errMessage.message);
                  setSelectedFiles((prevFiles) => ({
                    ...prevFiles,
                    [type]: file
                  }));
                  setIcons((prevIcons) => ({
                    ...prevIcons,
                    [type]: 'view'
                  }));
                  toast.error(allErrorMessages.join(" "));
                } 
                else if (errMessage.status =413 ){
                  toast.error("Document Size is large");
                }
                else if (errMessage.message && typeof errMessage.message === 'object') {
                  const errors = errMessage.message.errors || {};
                  for (const [key, messages] of Object.entries(errors)) {
                    if (Array.isArray(messages)) {
                      allErrorMessages.push(...messages);
                    }
                  }
                  setSelectedFiles((prevFiles) => ({
                    ...prevFiles,
                    [type]: null
                  }));
                  setIcons((prevIcons) => ({
                    ...prevIcons,
                    [type]: 'upload'
                  }));
                  toast.error(allErrorMessages.join(" "));
                }
                if (allErrorMessages.length > 0) {
                  setErrorMessage(allErrorMessages.join(" "));
                } 
              } 
            });}
        
      }; 
      const handleConfirmRemove = () => {
        if (documentToRemove && vehicle?.id) {
          const document = documentList.find(doc => 
          doc.document_name.replace(' ', '').toLowerCase() === documentToRemove.toLowerCase() &&
          doc.status === 'uploaded'
          );   
          if(documentId){
          deleteDocument(
            documentId,
            vehicle?.id, 
            (response) => {
              toast.success(response?.message);
              setSelectedFiles((prevFiles) => ({
                ...prevFiles,
                [documentToRemove]: null
              }));
              setIcons((prevIcons) => ({
                ...prevIcons,
                [documentToRemove]: 'upload'
              }));
              setDocumentLocation('');
              setSelectedInsuranceDate('');
              setGetInsuranceDate('');
              setSelectedFitnessDate('');
              setGetFitnessDate('');
              setErrorMessage('');
              setInitialInsuranceDate('');
              setInitialFitnessDate('');
            },
            (errorMessage) => {   
              toast.error(errorMessage.data.message);
            }
          );
        }
        else {
          deleteDocument(
            document?.id,
             vehicle?.id, 
             (response) => {
               toast.success(response?.message);
               setSelectedFiles((prevFiles) => ({
                 ...prevFiles,
                 [documentToRemove]: null
               }));
               setIcons((prevIcons) => ({
                 ...prevIcons,
                 [documentToRemove]: 'upload'
               }));
               setDocumentLocation('');
               setSelectedInsuranceDate('');
               setGetInsuranceDate('');
               setSelectedFitnessDate('');
               setGetFitnessDate('');
               setInitialInsuranceDate('');
               setInitialFitnessDate('');
             },
             (errorMessage) => {   
               toast.error(errorMessage.data.message);
             }
           );
        }
        } 
        else {
          toast.error('Document not found.');
        }
        setDocumentToRemove(null);
        setIsConfirmationOpen(false);
      }
      const handleInsuranceDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
        if (!selectedFiles.insurance && !getInsuranceDoc){
          setErrorMessage("Choose file to upload Insurance ");
        }
        if (value === ''){
          setErrorMessage('');
        }
        setSelectedInsuranceDate(value);
        if ((selectedFiles.insurance || getInsuranceDoc) && value) {
          if (!initialInsuranceDate) {
            setInitialInsuranceDate(event.target.value);
            uploadFile('insurance', selectedFiles?.insurance || getInsuranceDoc, value, selectedFitnessDate,'add');
          } else {
            uploadFile('insurance',selectedFiles?.insurance || getInsuranceDoc, value, selectedFitnessDate,'edit');
          }
        }
      };
      const handleFitnessDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
        if (!selectedFiles.fitnesscertificate && !getFitnessDoc){
          setErrorMessage("Choose file to upload Fitness Certificate");
        }
        if (value === ''){
          setErrorMessage('');
        }
        setSelectedFitnessDate(value);
        if ((selectedFiles.fitnesscertificate || getFitnessDoc) && value) {
          if(!initialFitnessDate){
            setInitialFitnessDate(value);
          uploadFile('fitnesscertificate', selectedFiles.fitnesscertificate,'', value,'add');
          }else{
            uploadFile('fitnesscertificate',selectedFiles.fitnesscertificate,'',value,'edit');
          }
        }
      };
      const handleCancelRemove = () => {
        setDocumentToRemove(null);
        setIsConfirmationOpen(false);
      };
      const validatePhoneNumber = (value: string, name:string) => {
        if (/[^0-9]/.test(value)) {
          if (name === 'owner') {
            setOwnerError('Invalid Phone Number');
          } else {
            setDriverError('Invalid Phone Number');
          }
          return;
        }
        if (value.length > 10) {
          if (name === 'owner') {
            setOwnerError('Phone number cannot exceed 10 digits.');
          } else {
            setDriverError('Phone number cannot exceed 10 digits.');
          }
          return;
        } 
        if (value.length === 10) {
          if (name === 'owner'){
            setOwnerError('')
          } else {
            setDriverError('')
          }
          return;
        }
        if (/^[0-5]/.test(value)) {
          if (name === 'owner') {
            setOwnerError('Phone number must start with 6,7,8,9');
          } else {
            setDriverError('Phone number must start with 6,7,8,9');
          }
          return;
        }
        if (name === 'owner' && value === '') {
          setOwnerError('');
        } else if (name === 'driver' && value === ''){
          setDriverError('');
        }   
      };
      const validateMakeYear = (value: string) => {
        if (value === '') {
          setMakeYearError(''); 
          return;
        }
        if (!/^\d+$/.test(value)) {
          setMakeYearError('Year must be a number.');
          return;
        }
          const year = parseInt(value, 10);
          const currentYear = new Date().getFullYear();
        if (value.length === 4 && year <= 1999) {
          setMakeYearError('Year must be greater than 1999.');
        } else if (year> currentYear){
          setMakeYearError(`Year must be less than or equal to ${currentYear}.`);
        }
        else {
          setMakeYearError(null); 
        }
      }
      const handleIconClick = (type: string, action: string) => {
        if (action === 'view' && (type === 'pollutioncertificate' || type === 'registrationcertificate')) {
          const document = documentList.find(doc => doc.document_name.replace(' ', '').toLowerCase() === type.toLowerCase() && doc.status === 'uploaded');
          if(document && !documentDeleted){ 
            const url = document.location;
            window.open(`${global.documents_image_url}${url}`, '_blank');      } 
          else if (documentLocation){
            const url = documentLocation;
            window.open(`${global.documents_image_url}${url}`, '_blank');      }
          else{
            toast.error('Document not found.');
          }
        } else if (action === 'view' &&((type === 'fitnesscertificate' && (getFitnessDate|| selectedFitnessDate)) || (type === 'insurance' && (getInsuranceDate|| selectedInsuranceDate))) ){
          const document = documentList.find(doc => doc.document_name.replace(' ', '').toLowerCase() === type.toLowerCase() && doc.status === 'uploaded');
          if(document && !documentDeleted){
            const url = document.location;
            window.open(`${global.documents_image_url}${url}`, '_blank');      } 
          else if (documentLocation){
            const url = documentLocation;
            window.open(`${global.documents_image_url}${url}`, '_blank');      }
          else{
            toast.error('Document not found.');
          }
        } 
        else if (action === 'remove') {
            setDocumentToRemove(type);
            setIsConfirmationOpen(true);
            setDocumentDeleted(true);      
        }
      };
      const fetchDriverDetails = (phoneNumber: string, name: string) => {
        if (phoneNumber.length < 10 && phoneNumber.length > 0){
          if (name === 'owner'){
            setOwnerError("Phone number must be 10 digits")         
          }
          else if (phoneNumber.length < 10 && phoneNumber.length > 0){
            setDriverError("Phone number must be 10 digits")
          }
          else{
            setDriverError('');
            setOwnerError('');
          }
          return;
        }
        if (phoneNumber.length === 10) {
          getDriverName(
            phoneNumber,
            (response: any) => {
              if (response.status === 200 ) {
                const user = response.message.user[0];
                if (name === 'owner') {
                  setOwnerUserName(user.user_name);
                  setOwnerPhoneNumber(user.user_phone);
                  setOwnerId(user.user_unique_id);
                } else if (name === 'driver') {
                  setDriverUserName(user.user_name);
                  setDriverPhoneNumber(user.user_phone);
                  setDriverId(user.user_unique_id);
                }
              } 
            },
            (errMessage: any) => {        
              if(errMessage.status === 400) {
                if(errMessage.message.user.length === 0){
                  if (name === 'owner'){
                    setOwnerError("User Not found");
                  }
                  else {
                    setDriverError("User Not Found")
                  }                
                }    
                else
                toast.error(errMessage.message);
              }
            }
          );
        } 
      }; 
      const isEqual = (obj1, obj2) => {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
        if (keys1.length !== keys2.length) return false;
        for (let key of keys1) {
          if (obj1[key] !== obj2[key]) return false;
        }
        return true;
      };
      
      const handleSave = () => {
          if (isEqual(formValues, initialValues) && clientData === selectedClient[0]?.label && fuelType[0].id === vehicle?.fuel_type) {
         onClose();
          return;
        }
        if (!formValues.model) {
          setModelError('This field is required.');
          return;
        }
      if (!formValues.make_year) {
        setMakeYearError('This field is required.');
        return;
      }     
      if (!driverUserName ){
        setDriverError('This field is required.');
        return;
      }
      if(selectedClient.length === 0){
        setClientError('This field is required.');
        return;
      }
      if (ownerError || driverError || makeYearError || modelError || errorMessage) {
        toast.error('Please fix the errors before saving.');
        return;
      }   
      editVehicle(
        vehicle?.vehicle_no,
        driverId,
        formValues.model,
        formValues.make_year,
        ownerId ,
        fuelName,
        (response: any) => {
          if(response.status == 200){
            toast.success(response.message.message);
            const clientId = selectedClient.length > 0 ? parseInt(selectedClient[0].id, 10) : null; 
            if (clientId){
              addClientUserMapping(
              driverId || vehicle?.driver_id,
              clientId,
              (response:any) => {
                if(response.status === 200){
                  toast.error(response?.message);
                }
                else if (response.status ===201){
                 toast.success(response);
                }
              },
              (errorMessage) => {
                toast.error(errorMessage); 
              }
              );
            }
            if(vendorId) {
               addVendorUserMapping(
                driverId || vehicle?.driver_id,
                  vendorId,
                (message) => {
                  toast.success(message);
                 },
                (errorMessage) => {
                  toast.error(errorMessage);
                   }
              ); }
          }
        },
        (errMessage: any) => {
          toast.error(errMessage.message);
        })      
        onClose(); 
        setTimeout(() => {
          window.location.reload();
      }, 3000);
      };
      const isValidFileType = (file: File, allowedTypes: string[]) => {
      return allowedTypes.includes(file.type);
      };    
      const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
        const fileInput = event.target; 
        const file = event.target.files?.[0] || null;      
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];
        const maxFileSize = 1 * 1024 * 1024; 
        if (!file) {
          toast.error('No file selected. Please choose a file to upload.');
          return;
        }
        if (!isValidFileType(file, allowedTypes)) {
          toast.error('Invalid file type. Please upload a jpeg, jpg, png, or pdf file.');
          return;
        }
        if (file.size > maxFileSize) {
          toast.error('File size exceeds 1 MB. Please upload a smaller file.');
          return;
        }
        setSelectedFiles((prevFiles) => ({
          ...prevFiles,
          [type]: file
        }));
        setIcons((prevIcons) => ({
          ...prevIcons,
          [type]: 'upload'
        }));     
        if (vehicle?.id && file) {
          if (type === 'fitnesscertificate' && !selectedFitnessDate) {            
            setErrorMessage('Expiry date is required for Fitness Certificate');
            setIcons((prevIcons) => ({
              ...prevIcons,
              [type]: 'view'
            }));
          } else if (type === 'insurance' && !selectedInsuranceDate) {         
            setErrorMessage('Expiry date is required for Insurance Certificate');
            setIcons((prevIcons) => ({
              ...prevIcons,
              [type]: 'view'
            }));
          } 
          else {
            setErrorMessage('');
            uploadFile(type, file, selectedFitnessDate, selectedInsuranceDate,'add');
          }
        }
        fileInput.value = '';
            };
      const clientOptions = clients.map((client) => ({
        id: client.id.toString(),
        label: client.name,
      }));
      const handleFuelTypeChange = (event: SelectChangeEvent<string>) => {
        const selectedId = event.target.value; 
        setFuelName(selectedId);
        const selectedOption = fuelTypeOptions.find(option => option.id === selectedId);
        if (selectedOption) {
          setFuelType([selectedOption]);
       } else {
          setFuelType([]);
        }
      };
      const handleClientDropdownChange = (event: SelectChangeEvent<string>) => {
        const selectedId = event.target.value;
        const selectedOption = clientOptions.find(option => option.id === selectedId);
        setClientError('');
        if (selectedOption) {
          setSelectedClient([selectedOption]);
       } else {
          setSelectedClient([]);
        }
      };
      const handleVendorDropdownChange = (event: SelectChangeEvent<string>) => {
        const selectedId = event.target.value;
        const selectedOption = vendorOptions.find(option => option.id === selectedId);
        if (selectedOption) {
          setSelectedVendor([selectedOption]);
        } else {
          setSelectedVendor([]);
        }
     };
      const vendorOptions = vendors.map((vendor) => ({
        id: vendor.id.toString(),
        label: vendor.name,
      }));
      const renderFileInput = (label: string, type: string) => (
        <div>
          <p style={{  display: 'inline-block', width: '165px', padding: '0px !important' }}>{label}</p>
          <input
            accept="*"
            style={{ display: 'none' }}
            id={`${type}-file`}
            type="file"
            onChange={(e) => handleFileChange(e, type)}
          />
          <label htmlFor={`${type}-file`}>
          <Tooltip title="Upload File">
              <IconButton color="primary" component="span" sx={{ padding: 0 }} >
                {icons[type] === 'upload' && <UploadIcon />}
              </IconButton>
            </Tooltip>
          </label>
          {icons[type] === 'view' && (
            <>
              <Tooltip title="View File">
                <span
                  style={{ cursor: 'pointer', color: 'blue' }}
                  onClick={() => handleIconClick(type, 'view')}
                >
                  View
                </span>
              </Tooltip>
              <Tooltip title="Remove File">
                <IconButton sx={{ color: 'red' }} component="span" onClick={() => handleIconClick(type, 'remove')}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>
      );
      return (
        <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { width: '750px', height: 'auto' } }}>
          <DialogTitle>
            <Box
              sx={{
                marginLeft: {
                  sm: '-180px',
                },
              }}
            >
              <Heading title={`Vehicle - ${formValues.vehicle_no}`} closeIcon="true" onClose={handleClose} />
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '15px',
                '@media (max-width: 600px)': {
                  flexDirection: 'column',
                },
              }}
            >
              <TextField
                label="Model"
                size="small"
                name="model"
                value={formValues.model}
                onChange={handleChange}
                sx={{ width: { xs: '100%', sm: 200 } }}
                margin="normal"
                error={!!modelError}
                helperText={modelError}
              />
              <TextField
                size="small"
                label="Make Year"
                name="make_year"
                value={formValues.make_year}
                onChange={handleChange}
                onBlur={handleMakeYearBlur}
                sx={{ width: { xs: '100%', sm: 200 } }}
                margin="normal"
                error={!!makeYearError}
                helperText={makeYearError}
              />
              <TextField
                size="small"
                label={ownerUserName ? 'Owner Name' : 'Owner Phone Number'}
                placeholder="Owner Phone Number"
                name="owner"
                value={isOwnerFocused ? ownerPhoneNumber : ownerUserName || ownerPhoneNumber}
                onChange={handlePhoneNumberChange}
                sx={{ width: { xs: '100%', sm: 200 } }}
                error={!!ownerError}
                helperText={ownerError}
                onFocus={handleOwnerFocus}
                onBlur={handleOwnerBlur}
              />
              <TextField
                label={driverUserName ? 'Driver Name' : 'Driver Phone Number'}
                size="small"
                name="driver"
                placeholder="Driver Phone Number"
                value={isDriverFocused ? driverPhoneNumber : driverUserName || driverPhoneNumber}
                onChange={handlePhoneNumberChange}
                onFocus={handleDriverFocus}
                onBlur={handleDriverBlur}
                error={!!driverError}
                helperText={driverError}
                sx={{ width: { xs: '100%', sm: 200 } }}
              />
              <Dropdown
                options={fuelTypeOptions}
                selectedValue={fuelType.length > 0 ? fuelType[0].id : ''}
                handleChange={handleFuelTypeChange}
                placeholder="Select Fuel"
                label="Select Fuel Type"
                width="200px"
                height="40px"
              />
              {userRole === 'vendor_supervisor' || userRole === 'vendor_manager' ? (
                <>
                  <Dropdown
                    options={clientOptions}
                    selectedValue={selectedClient.length > 0 ? selectedClient[0].id : ''}
                    handleChange={handleClientDropdownChange}
                    placeholder="Select Client"
                    label="Select Client"
                    width="200px"
                    height="40px"
                    error={!!clientError}
                    helperText={clientError}
                  />
                  <TextField
                    size="small"
                    type="text"
                    name="vendorName"
                    disabled
                    value={vendorName}
                    label="Vendor Name"
                    sx={{ width: { xs: '100%', sm: 200 } }}
                  />
                </>
              ) : userRole === 'client_supervisor' || userRole === 'client_manager' ? (
                <>
                  <Dropdown
                    options={vendorOptions}
                    selectedValue={selectedVendor.length > 0 ? selectedVendor[0].id : ''}
                    handleChange={handleVendorDropdownChange}
                    placeholder="Select Vendor"
                    label="Select Vendor"
                    width="200px"
                    height="40px"
                  />
                  <TextField
                    size="small"
                    variant="outlined"
                    type="text"
                    name="clientName"
                    value={clientName}
                    disabled
                    sx={{ width: { xs: '100%', sm: 200 } }}
                  />
                </>
              ) : (
                <p style={{ color: 'red' }}>User role is not vendor_supervisor, vendor_manager, client_supervisor, or client_manager</p>
              )}
            </Box>
            <Box sx={{ marginTop: '10px' }}>
              {renderFileInput('Registration Certificate', 'registrationcertificate')}
            </Box>
            <Box sx={{ marginTop: '10px' }}>
              {renderFileInput('Pollution Certificate', 'pollutioncertificate')}
            </Box>
            <Box sx ={{ display: 'flex', flexWrap:'wrap'}}>
            <Box>
              {renderFileInput('Fitness Certificate', 'fitnesscertificate')}
              
            </Box>
            <DatePicker
                id="fitnessExpiryDate"
                name="fitnessExpiryDate"
                value={selectedFitnessDate || getFitnessDate || ''}
                label="Expiry Date"
                onChange={handleFitnessDateChange}
                labelMargin={'30px'}
              />
            </Box>
            <Box sx ={{ display: 'flex', flexWrap:'wrap'}}>
            <Box>
              {renderFileInput('Insurance', 'insurance')}
            </Box>
            <DatePicker
                id="insuranceExpiryDate"
                name="insuranceExpiryDate"
                value={selectedInsuranceDate || getInsuranceDate || ''}
                label="Expiry Date"
                onChange={handleInsuranceDateChange}
                labelMargin={'30px'}
              />
            </Box>
            {errorMessage && (
            <div style={{ color: 'red', marginTop: '-10px', fontSize:'15px' }}>
              {errorMessage}
            </div>
          )}
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
            <Button className="custom-button" onClick={handleSave}>
              Save
            </Button>
            <Button className="custom-cancel-button" onClick={handleClose}>
              Cancel
            </Button>
          </DialogActions>
          <ConfirmationPopup
            open={isConfirmationOpen}
            onClose={handleCancelRemove}
            onConfirm={handleConfirmRemove}
            title="Confirm Removal"
            message="Are you sure you want to remove this file?"
          />
        </Dialog>
      );
    };
    export default EditVehicleModal;