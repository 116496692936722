import React, {useState, useEffect} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Button,Box, TextField} from '@mui/material';
import Heading from '../../Components/Heading/heading.tsx';
import '../../Components/Table/table-style.scss';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {validatePhoneNumber} from '../../Utils/PhoneNumberValidation/phone-number-validation.tsx';
import {editDriverLead, addNonExistingDriver} from './action.ts';
import {validatePincode} from '../../Utils/PincodeValidation/pincode-validation.tsx';
import {validateDriverName} from '../../Utils/NameValidation/name-validation.tsx';
import {validateEmail} from '../../Utils/EmailValidation/email-validation.tsx';
import { validateRemarks } from '../../Utils/RemarksValidation/remarks-validation.tsx';
import Dropdown from '../../Components/DropDown/drop-down.tsx';

interface DriverLeadData {
  name: string;
  pincode: string;
  phone: string;
  whatsapp_number: string;
  email: string;
  status : string;
  remarks : string;
}

interface EditDriverLeadModalProps {
  open: boolean;
  onClose: () => void;
  driverData: DriverLeadData | null;
  leadId : number;
  option : string;
  fetchUsers: (page: number, pageSize: number, lead: string | null, blacklist: string | null, left: string | null, tabName: string) => void;
  page : number;
  pageSize : number;
}
const EditDriverLeadModal: React.FC<EditDriverLeadModalProps> = ({ open, onClose,driverData ,leadId,option, fetchUsers ,page , pageSize }) => {
   
    const [countryCode, setCountryCode] = useState<string>('91');
    const [language, setLanguage] = useState<string>('EN');
    const [formValues, setFormValues] = useState({
      name: '',
      pincode: '',
      phone: '',
      whatsapp_number: '',
      email: '',
      remarks : '',
      status : '',
    });
    useEffect(() => {
      if (driverData) {
        setFormValues({
          name: driverData.name || '',
          pincode: driverData.pincode || '',
          phone: driverData.phone || '',
          whatsapp_number: driverData.whatsapp_number || '',
          email: driverData.email || '',
          remarks : driverData.remarks || '',
          status : driverData.status || '',
        });
      }
    }, [driverData]);
    const statusOptions = [
      { id: 'blacklist', label: 'Blacklist'},
      { id: 'others', label: 'Others' },
      { id: 'recruited', label: 'Recruited' },
      { id: 'uninterested', label: 'Uninterested' },
      { id: 'lead', label: 'Lead' },
    ];
    const [emailError, setEmailError] = useState<string>('');
    const [nameError , setNameError] = useState<string>('');
    const [phoneError , setPhoneError] = useState<string>('');
    const [pincodeError , setPincodeError] = useState<string>('');
    const [remarksError , setRemarksError] = useState<string>('');
    const resetState = () => {
      setNameError('');
      setEmailError('');
      setPincodeError('');
      setRemarksError('');
    };
    const number = 1;
    const stringValue = number.toString();
    const handleSave = () => {
      if(nameError||pincodeError||phoneError||emailError||remarksError)
      {
        toast.error("Please fix the errors");
        return;
      }
      if (option==='edit'&&
        formValues.name === driverData?.name &&
        formValues.pincode === driverData?.pincode &&
        formValues.phone === driverData?.phone &&
        formValues.email === driverData?.email &&
        formValues.status === driverData?.status &&
        formValues.remarks === driverData?.remarks
      ) {
        handleClose();
        return;
      }
       if(!nameError&&!pincodeError&&!phoneError&&!emailError&&formValues.name&&formValues.pincode&&formValues.phone&&!remarksError){
        editDriverLead(
          leadId,
          formValues.name, 
          formValues.pincode, 
          formValues.phone, 
          formValues.email,
          formValues.status,
          formValues.remarks,
          (response) => {
             toast.success(response.message); 
             if (option==='edit'){
              setTimeout(() => {
                fetchUsers(page, pageSize, stringValue, null, null, 'lead');
              }, 3000);
             }
             if(option==='delete'){
                if(formValues.status==='blacklist'){
                   addNonExistingDriver(
                      '', 
                      leadId,
                      formValues.remarks,
                      formValues.status, 
                      (response) => {
                            toast.success(`Driver lead added to blacklist successfully`);
                            handleClose();
                            setTimeout(() => {
                              fetchUsers(page, pageSize, stringValue, null, null, 'lead');
                            }, 1000);
                      },
                      (error) => {
                            toast.error(error.message);
                      }
                  );}
                else {
                  toast.error("Not added to blacklist");
                  handleClose();
                  setRemarksError("");
                  setTimeout(() => {
                    fetchUsers(page, pageSize, stringValue, null, null, 'lead');
                  }, 1000);
               }
              }
            },
            (errorMessage) => {
                 toast.error(errorMessage);
             }
          );
         onClose();
         }
    };
    const handleClose = () => {
        onClose();
        resetState();
    };
    const handleNameChange = (event) => {
      const { value } = event.target;
      if (value === '') {
        setNameError('');
      } else if (validateDriverName(value)) {
        setNameError('');
      } else {
        setNameError('Characters are allowed');
      }
      setFormValues(prev => ({
        ...prev,
        name: value,
    }));
   };
   const handleEmailChange = (event) => {
        const { value } = event.target;
        setEmailError('');
        setFormValues(prev => ({
          ...prev,
          email: value,
      }));
    };
    const handleRemarksChange = (event) => {
      const { value } = event.target;
      if (value === '') {
        setRemarksError('');
      } else if (validateRemarks(value)) {
        setRemarksError('');
      } else {
        setRemarksError('Invalid Character');
      }
      setFormValues(prev => ({
        ...prev,
        remarks: value,
      }));
    };
    const handleEmailBlur = () => {
        if (formValues.email === '') {
          setEmailError('');
        } else if (validateEmail(formValues.email)) {
          setEmailError('');
        } else {
          setEmailError('Please enter a valid email address.');
        }
    };
    const handlePhoneNumberChange = (event) => {
        const { value } = event.target;
        if(value==='')
        {
          setPhoneError("");
        }
        else{
        const { errorMessage } = validatePhoneNumber(value);
        setPhoneError(errorMessage);}
        setFormValues(prev => ({
          ...prev,
          phone: value,
      }));
    };  
    const handlePincodeBlur = () => {
      if(formValues.pincode==='')
      {
        setPincodeError(" ");
      }
       if(formValues.pincode.length>=1&&formValues.pincode.length<6)
       {
        setPincodeError("Pincode must be 6 digits");
       }
    };  
    const handlePincodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setFormValues(prev => ({
            ...prev,
            pincode: value,
        }));
        const error = validatePincode(value);
        setPincodeError(error);
    };
    const handlePhoneNumberBlur = () => {
      if (formValues.phone === '') {
        setPhoneError('');
      } else if (formValues.phone.length>=1&&formValues.phone.length<10) {
        setPhoneError('Please enter a 10 digit number');
      } 
    };
    const handleNameBlur = () => {
        if (nameError=== '') {
          setNameError('');
        } else  {
          setNameError(nameError);
        } 
    };
    const handleRemarksBlur = () => {
      if (remarksError=== '') {
        setRemarksError('');
      } else  {
        setRemarksError(remarksError);
      } 
    };
    const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedValue = event.target.value;
      setFormValues((prev) => ({
        ...prev,
        status: selectedValue, 
      }));
    };
    
    return (
      <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { width: '800px', height: 'auto' } }}>
      <DialogTitle>
      <Box sx={{ marginLeft: {
      sm: '-180px', 
    }, }}>          
    <Heading title={`${option === 'edit' ? 'Edit Lead Data' : 'Delete Lead Data'}`} closeIcon="true" onClose={handleClose} />
        </Box>    
      </DialogTitle>
      <DialogContent>
        {option === 'edit' && (
          <>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', margin: '10px 30px' }}>
              <TextField
                variant="outlined"
                size="small"
                label="Name"
                name="name"
                value={formValues.name}
                onChange={(e) => handleNameChange(e)}
                onBlur={handleNameBlur}
                error={!!nameError}
                helperText={nameError}
                style={{ width: '225px' }}
              />
              <TextField
                variant="outlined"
                size="small"
                label="Phone"
                name="phone"
                value={formValues.phone}
                onChange={(e) => handlePhoneNumberChange(e)}
                onBlur={handlePhoneNumberBlur}
                error={!!phoneError}
                helperText={phoneError}
                style={{ width: '225px' }}
              />
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', margin: '10px 30px' }}>
            <TextField
                variant="outlined"
                size="small"
                label="Pincode"
                name="pincode"
                value={formValues.pincode}
                onChange={(e) => handlePincodeChange(e)}
                onBlur={handlePincodeBlur}
                error={!!pincodeError}
                helperText={pincodeError}
                style={{ width: '225px' }}
              /> 
              <TextField
                variant="outlined"
                size="small"
                label="Email"
                name="email"
                value={formValues.email}
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                error={!!emailError}
                helperText={emailError}
                style={{ width: '225px' }}
              />
            </div>
          </>
        )}
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', margin: '10px 30px' }}>
          <Dropdown
            options={statusOptions}
            selectedValue={formValues.status}
            handleChange={handleStatusChange}
            label="Status"
            width="225px"
            height="41px"
          />
          <TextField
            variant="outlined"
            size="small"
            label="Remarks"
            name="remarks"
            value={formValues.remarks}
            onChange={(e) => handleRemarksChange(e)}
            onBlur={handleRemarksBlur}
            error={!!remarksError}
            helperText={remarksError}
            style={{ width: '225px' }}
          />
        </div>
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
        <Button className='custom-button' onClick={handleSave}>Save</Button>
        <Button className='custom-cancel-button' onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
   );
  };
  export default EditDriverLeadModal;