import React,{useState, useEffect, useRef } from 'react';
import NavBar from '../../Components/Navbar/sidenav.tsx';
import Header from '../../Components/Header/header.tsx';
import Dropdown from '../../Components/DropDown/drop-down.tsx';
import DatePicker from '../../Components/DatePicker/date-picker.tsx';
import Heading from '../../Components/Heading/heading.tsx';
import formatDate from '../../Utils/DateFormat/date-format.tsx';
import getCurrentDate from '../../Utils/CurrentDate/current-date.tsx';
import Newpagination from '../../Components/Pagination/pagination.tsx';
import ConfirmationPopup from '../../Components/ConfirmationModal/confirmation-modal.tsx';
import LocationModal from '../Trips/location-modal.tsx';
import TripDetailsModal from './trip-details-modal.tsx';
import { getVehicle, getTrips, editRemarks,getClients } from '../Trips/action.ts';
import { useNavigate } from 'react-router-dom';
import {  Button } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { SelectChangeEvent } from '@mui/material/Select';
import '@fortawesome/fontawesome-free/css/all.min.css';
import IconButton from '@mui/material/IconButton';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../Components/Button/button-style.scss';
import '../../Components/Table/table-style.scss';
import DownloadIcon from '@mui/icons-material/Download';
import { Box} from '@mui/material';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

interface TripsProps { }
interface Vehicle {
  id: string;
  number_plate: string;
}
interface Trip {
  id: number;
  vehicles_id: string;
  start_time: string;
  end_time: string;
  start_location: string;
  end_location: string;
  odometer_start: number;
  odometer_end: number;
  gps_kms: number;
  remarks: string;
  odometer_start_upload_url:any;
  odometer_end_upload_url:any;
  orders_picked:number;
  orders_delivered:number;
}
const Trips: React.FC<TripsProps> = () => {
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [vehicleOptions, setVehicleOptions] = useState<{ id: string, label: string }[]>([]);
  const [clientOptions,setClientOptions]= useState<{id: string , label: string}[]>([]);
  const [selectedFromDate, setSelectedFromDate] = useState<string>('');
  const [selectedToDate, setSelectedToDate] = useState<string>('');
  const [selectedVehicle, setSelectedVehicle] = useState<any>('');
  const [selectedFuelType,setSelectedFuelType] = useState<string>('');
  const [selectedClient, setSelectedClient] = useState<{ id: string, name: string } | null>(null);
  const [tripsForRender, setTripsForRender] = useState<Trip[]>([]);
  const [remarksList, setRemarksList] = useState<{ [key: number]: string }>({});
  const [startReadingList, setStartReadingList] = useState<{ [key: number]: string }>({});
  const [endReadingList, setEndReadingList] = useState<{ [key: number]: string }>({});
  const [modalOpen, setModalOpen] = useState(false);
  const [tripDetailsModalOpen, setTripDetailsModalOpen] = useState(false);
  const [modalStartLocation, setModalStartLocation] = useState<string>('');
  const [modalEndLocation, setModalEndLocation] = useState<string>('');
  const [tripId, setTripId] = useState<string>('');
  const [vehicleId, setVehicleId] = useState<string>('');
  const navigate = useNavigate();
  const [vehicleList, setVehicleList] = useState<Vehicle[]>([]);
  const [clientList, setClientList] = useState<Vehicle[]>([]);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [pendingAction, setPendingAction] = useState<(() => void) | null>(null);
  const [modalType, setModalType] = useState<'start' | 'end' | null>(null);
  const orderRef = useRef<any>();
  const isLoggedIn = !!localStorage.getItem("userdetails");
  const gridContainerRef = useRef<HTMLDivElement>(null);
  const minDate = new Date('2023-01-01');
  const maxDate = new Date(); 
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(tripsForRender.map(trip => ({
      'Sl No': (page * pageSize) + tripsForRender.indexOf(trip) + 1,
      'Vehicle Number':  vehicleList.find((vehicle) => vehicle.id === trip.vehicles_id)?.number_plate || '',
      'Start Time': trip.start_time,
      'End Time': trip.end_time,
      'Odometer Start': trip.odometer_start,
      'Odometer End': trip.odometer_end,
      'Distance': trip.odometer_end - trip.odometer_start < 0 ? '' : trip.odometer_end - trip.odometer_start,    gps_km: trip.gps_kms ? Number(trip.gps_kms).toFixed(2) : '',
      'Orders Delivered/Picked Up': `${trip.orders_delivered}/${trip.orders_picked}`,
      'Remarks': trip.remarks,
    })));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Trips');
    const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'trips_report.xlsx');
  };

  useEffect(() => {
    if (!isLoggedIn) {
        navigate('/');
    }
    const today = getCurrentDate();
    setSelectedFromDate(today);
    setSelectedToDate(today);
    fetchClients();
  }, [isLoggedIn]);

  useEffect(() => {
   
    fetchVehicles();  
  }, [selectedClient,selectedFuelType]);

  const handleOpenConfirm = (action: () => void) => {
    setPendingAction(() => action);
    setConfirmOpen(true);
  };

  const handleCloseConfirm = () => {
    setConfirmOpen(false);
    setPendingAction(null);
  };
  
  const handleConfirm = () => {
    if (pendingAction) {
      pendingAction();
    }
    handleCloseConfirm();
  };
  
  const handleKeyDownOdometerStart = (event: React.KeyboardEvent<HTMLInputElement>, trip_id: number) => {
    if (event.key === 'Enter') {
      handleBlurOdometerStart(trip_id);
    }
  };

  const handleKeyDownOdometerEnd = (event: React.KeyboardEvent<HTMLInputElement>, trip_id: number) => {
    if (event.key === 'Enter') {
      handleBlurOdometerEnd(trip_id);
    }
  };

  const fetchVehicles = () => {
    getVehicle(
      50,
      0,
      selectedClient?.name,
      selectedFuelType,
      (response: any) => {
        setVehicleList(response.message?.vehicles_list);
        const options = response.message?.vehicles_list.map((vehicle: any) => ({
            id: vehicle.id,
            label: vehicle.number_plate,
        }));
        setVehicleOptions(options);
      },
      (errMessage: any) => {
          toast.error(errMessage);
      }
    );
  };
  const fetchClients = () => {
    getClients(
      (response:any) => {
        setClientList(response.message.clients);
        const options = response.message.clients.map ((clients:any) => ({
          id:clients.id,
          label:clients.name,
        }));
        setClientOptions(options);
      },
      (errMessage:any) => {
        toast.error(errMessage);
      }
    );
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "fromDate") {
      setSelectedFromDate(value);
      const toDateInput = document.getElementById("toDate") as HTMLInputElement;
        if (toDateInput) {
            toDateInput.min = value;
        }
    } 
    else if (name === "toDate") {
      if (new Date(value) >= new Date(selectedFromDate)) {
          setSelectedToDate(value);
      } else {
          toast.error("To date must be greater than or equal to From date");
      }
    }
  };
  const handleVehicleChange = (event: SelectChangeEvent<string>) => {
    setSelectedVehicle(event.target.value);
  };
  const handleClientChange = (event: SelectChangeEvent<string>) => {
    const clientId = event.target.value;
    const client = clientOptions.find(c => c.id === clientId);
    const transformedClient = client ? { id: client.id, name: client.label } : null;
    setSelectedClient(transformedClient);
    setSelectedVehicle('');
  }
  const handleFuelChange = (event:SelectChangeEvent<string>) => {
    setSelectedFuelType(event.target.value);
    setSelectedVehicle('');
  }
  const handleCloseModal = () => {
    setModalOpen(false);
    setModalType(null);
  };
  const handleTripDetailsCloseModal = () => {
  setTripDetailsModalOpen(false);
  };
  const truncateLocation = (location: string) => {
    const commaCount = 3;
    const match = location.match(/(.*?)(\d{6})(.*)/);
    const sixDigitMatch = match ? `,${match[2]}` : '';
    const parts = location.split(',');
    if (parts.length <= commaCount) {
      return location;
    }
    const truncatedLocation = parts.slice(0, commaCount).join(',') + sixDigitMatch;
    return truncatedLocation;
  };
  const handleRemarksChange = (
    input: string, 
    trip_id: number, 
    type: 'remarks' | 'odometer_start' | 'odometer_end',
    successCallBack: (response: any) => void, 
    errorCallback: (errMessage: any) => void
  ) => 
  {
    if (type === 'remarks' || type === 'odometer_start' || type === 'odometer_end') {
      editRemarks(trip_id, type, input, successCallBack, errorCallback);
    } else {
      console.error('Invalid input type');
    }
  };
  const handleRemarksInput = (event: React.ChangeEvent<HTMLInputElement>, trip_id: number) => {
    const newValue = event.target.value;
    setRemarksList((prevRemarksList) => {
      return { ...prevRemarksList, [trip_id]: newValue };
    });
  };
      
  const handleOdometerStart = (event: React.ChangeEvent<HTMLInputElement>, trip_id: number) =>  {
    const newValue = event.target.value;
    setStartReadingList((prevStartReadingList) => {
      return { ...prevStartReadingList, [trip_id]: newValue };
    });
  };
      
  const handleOdometerEnd = (event: React.ChangeEvent<HTMLInputElement>, trip_id: number) => {
    const newValue = event.target.value;
    setEndReadingList((prevEndReadingList) => {
      return { ...prevEndReadingList, [trip_id]: newValue };
    });
  };
  const handleBlurOdometerStart = (trip_id: number) => {
    const odometerEnd = Number(endReadingList[trip_id]);
    const odometerStart = Number(startReadingList[trip_id] || 0);  
    const previousOdometerStart = tripsForRender.find(trip => trip.id === trip_id)?.odometer_start;
    if (odometerStart !== null && odometerEnd >= odometerStart) {
      const distance = odometerEnd - odometerStart;
      setTripsForRender(prevTrips =>
        prevTrips.map(trip => 
          trip.id === trip_id ? 
          { ...trip, odometer_start: odometerStart, distance_travelled: distance } : 
          trip
        )
      );
      if (odometerStart !== previousOdometerStart) { 
        handleOpenConfirm(() => {
        handleRemarksChange(startReadingList[trip_id], trip_id, 'odometer_start', successCallBack, errorCallback);
        });
      }
    }else {  
      toast.error("Odometer end reading must be greater than or equal to odometer start reading.");
      const previousValue = tripsForRender.find(trip => trip.id === trip_id)?.odometer_start;
      if (previousValue !== undefined) {
        setStartReadingList(prevStartReadingList => ({
          ...prevStartReadingList,
          [trip_id]: previousValue.toString(),
        }));
      }
    }
  };
  const handleBlurOdometerEnd = (trip_id: number) => {
    const odometerEnd = Number(endReadingList[trip_id]);
    const odometerStart = Number(startReadingList[trip_id] || 0); 
    const previousOdometerEnd = tripsForRender.find(trip => trip.id === trip_id)?.odometer_end;  
    if (odometerStart !== null && odometerEnd >= odometerStart) {
      const distance = odometerEnd - odometerStart;
      setTripsForRender(prevTrips =>
        prevTrips.map(trip => 
          trip.id === trip_id ? 
          { ...trip, odometer_end: odometerEnd, distance_travelled: distance } : 
          trip
        )
      );
      if (odometerEnd !== previousOdometerEnd) { 
        handleOpenConfirm(() => {
          handleRemarksChange(endReadingList[trip_id], trip_id, 'odometer_end', successCallBack, errorCallback);
        });
      }
    } 
    else{  
      toast.error("Odometer end reading must be greater than or equal to odometer start reading.");
      const previousValue = tripsForRender.find(trip => trip.id === trip_id)?.odometer_end;
      if (previousValue !== undefined) {
        setEndReadingList(prevEndReadingList => ({
          ...prevEndReadingList,
          [trip_id]: previousValue.toString(),
        }));
      }
    }
  };     
  const successCallBack = (response: any) => {
    if (response.status === 200) {
        toast.success(response.message);
    } else if (response.status === 400) {
        toast.error(response.message);
    }
  };
  const errorCallback = (errorMsg: string) => {
      toast.error(errorMsg);
  };
  const updateField = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    if (!isNaN(Number(value))) {
      setPageSize(Number(value));
      setPage(0);
    }
  };
  const handlePageClick = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage - 1);
  };
  useEffect(() => {
    if (selectedFromDate && selectedToDate) {
      fetchData();
    }
  }, [pageSize, page, selectedFromDate, selectedToDate, selectedVehicle,selectedClient,selectedFuelType]);
  const fetchData = () => {
    getTrips(
      pageSize,
      page,
      selectedFromDate,
      selectedToDate,
      (response: any) => {
        if (response.status === 400) {
          toast.error(response.message.trips_list);
          setTripsForRender([]);
          setTotalRecords(0);
        } else {
          const totalTripsCount = response.message.total_trips_count;
          setTotalCount(Math.ceil(totalTripsCount / pageSize));
          setTotalRecords(totalTripsCount);
          const tripsList = response.message.trips_list || [];
          setTripsForRender(tripsList);
          const initialRemarks: { [key: number]: string } = {};
          const initialStart:{[key:number]:string} = {};
          const initialEnd:{[key:number]:string} = {};
          tripsList.forEach((trip: Trip) => {
            initialRemarks[trip.id] = trip.remarks || '';
            initialStart[trip.id] =  String(trip.odometer_start) || '';
            initialEnd[trip.id] =  String(trip.odometer_end) || '';
          });
          setRemarksList(initialRemarks);
          setStartReadingList(initialStart);
          setEndReadingList(initialEnd);
        }
      },
      (errMessage: any) => {
          setTotalCount(0);
          setTotalRecords(0);
          toast.error(errMessage);
      },
      selectedVehicle,
      selectedClient?.name || '',
      selectedFuelType
    );
  };
  const calculateColumnWidths = (containerWidth: number): GridColDef[] => {
    const totalFlex = columns.reduce((acc, col) => acc + (col.flex || 1), 0);
    return columns.map(col => ({
      ...col,
      width: containerWidth * (col.flex || 1) / totalFlex,
    }));
  };
  const handleLocationClick = (startLocation: string, endLocation: string) => {
    setModalStartLocation(startLocation);
    setModalEndLocation(endLocation);
    setModalOpen(true);
  };
  const columns: GridColDef[] = [
    { field: 'sl_no', headerName: 'Sl No', width: 50 , headerAlign:'center'},
    {field: 'Vehicle_no',headerName: 'Vehicle Number',width:200, headerAlign:'center',
    },
    { field: 'start_time', headerName: 'Start Time',width:150, headerAlign:'center',
    },
    {
      field: 'end_time',
      headerName: 'End Time',
      width: 150,
      headerAlign:'center',
      renderCell: (params) => {
        if (!params.value) {
          return (
            <Button 
              variant="contained" 
              style={{ backgroundColor: 'red', color: 'white' }}
              size="small"
              onClick={() => handleEndTrip(params.row.id, params.row.vehicles_id)}
            >
              End Trip
            </Button>
          );
        }
        return params.value;
      },
    },
    { field: 'odometer_reading_start',
      headerName: 'Odometer Start',
      width: 120,
      editable: true,
      headerAlign:'center',
      renderCell: (params) => {
        const value = startReadingList[params.row.id] ?? '';
        const url = params.row.odometer_start;
        return <div style={{
          border: '1px solid grey',
          borderRadius: '5px',
          outline: 'none',
          boxShadow: 'none',
          width: '100%',
          height: '100%',        
          boxSizing: 'border-box',
        }}><div >{value} {url && (
          <a
            href={`${global.odometer_image_url}${url}`}
            target="_blank"
            rel="noopener noreferrer"
            download
          >
          <i className="fas fa-download"></i>
          </a>
        )}</div></div>;
      },
      renderEditCell: (params: GridRenderCellParams) => (
        <div style={{ position: 'relative' }}>
          <input
            type="number"
            value={startReadingList[params.row.id] ?? ''}
            onChange={(e) => handleOdometerStart(e, params.row.id)}
            onBlur={() => handleBlurOdometerStart(params.row.id)}
            onKeyDown={(event) => handleKeyDownOdometerStart(event, params.row.id)}
            style={{
              border: '1px solid black',
              outline: 'none',
              boxShadow: 'none',
              width: '100%',
            }}
          />       
        </div>
      ),
    },  
    {
      field: 'odometer_reading_end',
      headerName: 'Odometer End',
      width:120,
      editable: true,
      headerAlign:'center',
      renderCell: (params) => {
        const value = endReadingList[params.row.id] ?? '';
        const url = params.row.odometer_end;
        return <div style={{
          border: '1px solid grey',
          borderRadius: '5px',
          outline: 'none',
          boxShadow: 'none',
          width: '100%',
          height: '100%',        
          boxSizing: 'border-box',
        }}><div>{value} {url && (
          <a
            href={`${global.odometer_image_url}${url}`}
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            <i className="fas fa-download"></i>
          </a>
        )}</div></div>;
      },
      renderEditCell: (params: GridRenderCellParams) => (
        <input
          type="number"
          value={endReadingList[params.row.id] ?? ''}
          onChange={(e) => handleOdometerEnd(e, params.row.id)}
          onBlur={() => handleBlurOdometerEnd(params.row.id)}
          onKeyDown={(event) => handleKeyDownOdometerEnd(event, params.row.id)}
          style={{
            border: '1px solid black',
            outline: 'none',
            boxShadow: 'none',
            width: '100%',
          }}
        />
      ),
    },
    { field: 'distance_travelled', headerName: 'Distance', width:100, headerAlign:'center'},
    { field: 'orders_picked', headerName: 'Order Delivered/Picked Up',width:190, headerAlign:'center' },
    {
      field: 'remarks',
      headerName: 'Remarks',
      width:170,
      headerAlign:'center',
      editable: true,
      renderCell: (params) => {
        const value = remarksList[params.row.id] ?? '';
        return <div style={{
          border: '1px solid grey',
          borderRadius: '5px',
          outline: 'none',
          boxShadow: 'none',
          width: '100%',
          height: '100%',
         
          boxSizing: 'border-box',
        }}>{value}</div>;
      },
      renderEditCell: (params: GridRenderCellParams) => (
        <input
          type="text"
          value={remarksList[params.row.id] ?? ''}
          onChange={(e) => handleRemarksInput(e, params.row.id)}
          onBlur={() => handleRemarksChange(remarksList[params.row.id], params.row.id, 'remarks', successCallBack, errorCallback)}
          style={{
            border: '1px solid black',
            outline: 'none',
            boxShadow: 'none',
            width: '100%',
          }}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      flex:1, 
      headerAlign:'center',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              gap: '8px', 
            }}
          >
            <IconButton color="primary" onClick={() => handleLocationClick(params.row.start_location, params.row.end_location)}>
              <LocationOnIcon />
            </IconButton>
          </div>
        );
      },
    }  
  ];
  const rows = tripsForRender.map((trip, index) => {
    const vehicle_no = 
      vehicleList?.find((vehicle) => vehicle.id === trip.vehicles_id)?.number_plate || 'Unknown Vehicle';
  
    const driver_name = 
      vehicleList?.find((vehicle) => vehicle.id === trip.vehicles_id)?.driver_name || 'Unknown Driver';
  
    return {
      id: trip.id,
      vehicles_id:trip.vehicles_id,
      sl_no:(page * pageSize) + index + 1,
      Vehicle_no: `${vehicle_no}/${driver_name}`,
      start_time: formatDate(trip.start_time),
      end_time: trip.end_time ? formatDate(trip.end_time) : null,
      odometer_reading_start: trip.odometer_start,
      odometer_reading_end: trip.odometer_end,
      odometer_start: trip.odometer_start_upload_url,
      odometer_end: trip.odometer_end_upload_url,
      distance_travelled: trip.odometer_end - trip.odometer_start < 0 ? '' : trip.odometer_end - trip.odometer_start,
      gps_km: trip.gps_kms ? Number(trip.gps_kms).toFixed(2) : '',
      remarks: trip.remarks,
      start_location: trip.start_location,
      end_location: trip.end_location,
      orders_picked: 
        (trip.orders_delivered || trip.orders_picked)
          ? `${trip.orders_delivered}/${trip.orders_picked}`
          : '',
    };
  });
  const handleStartTrip = () => {
    setModalType('start');
    setTripDetailsModalOpen(true);
  };
  const handleEndTrip = (tripId, vehicleId) => {
    console.log(tripId,vehicleId);
    setVehicleId(vehicleId);
    setTripId(tripId);
    setModalType('end');
    setTripDetailsModalOpen(true);
  };
  const showEntries = [
    {id:'10',  label:'10'},
    { id: '20', label: '20' },
    { id: '50', label: '50' },
    { id: '100', label: '100' },
  ];
  const fuelTypeOptions = [
    { id: 'diesel', label: 'Diesel' },
    { id: 'EV', label: 'EV' },
    {id:'petrol',  label:'Petrol'},
    { id: 'CNG', label: 'CNG' },
  ];
  const startIndex = page * pageSize + 1;
  const endIndex = Math.min((page + 1) * pageSize, totalRecords);
  return (
    <>
      <Header />
      <div style={{ marginTop: "75px", marginRight: "5px" }}>
          <Heading 
            title="List Of Trips" 
            closeIcon="false" 
            page='trips'
            leftContent={ 
              <>
                <Box sx={{ marginRight: '10px', marginBottom: {
                  xs: '10px',
                  md:'10px',
                  lg:'0px'
                }, }}>
                  <Dropdown
                    options={vehicleOptions}
                    selectedValue={selectedVehicle}
                    handleChange={(event) => handleVehicleChange(event)}
                    placeholder="Choose a vehicle"
                    label="Choose a vehicle"
                    searchable={true}
                    width='190px'
                    height='50px'
                    name='number_plate'
                  /> 
                </Box>
                <Dropdown
                  options={clientOptions}
                  selectedValue={selectedClient?.id || ''}     
                  handleChange={(event) => handleClientChange(event)}
                  placeholder="Choose Client"
                  label="Choose Client"
                  width='190px'
                  height='50px'
                />
              </>
            }
            rightContent={ 
              <>
                <Box sx={{ marginRight:'10px',  marginBottom: {
                    xs: '10px',
                     md:'0px'
                  },}}>
                  <Dropdown
                    options={fuelTypeOptions}
                    selectedValue={selectedFuelType || ''}     
                    handleChange={(event) => handleFuelChange(event)}
                    placeholder="Choose Fuel Type"
                    label="Choose Fuel Type"
                    width='190px'
                    height='50px'
                  />
                </Box>
                <Box sx={{ marginRight: {xs:'0px', sm: '10px'}, marginBottom: {
      xs: '10px',
      md:'0px'
    }, }}>
                  <DatePicker
                    id="fromDate"
                    name="fromDate"
                    value={selectedFromDate}
                    label="From"
                    onChange={handleDateChange}
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                </Box>
                <Box sx={{ marginLeft: {xs:'20px', md: '0px'}}}>
                <DatePicker
                  id="toDate"
                  name="toDate"
                  value={selectedToDate}
                  label="To"
                  onChange={handleDateChange}
                  minDate={minDate}
                  maxDate={maxDate}
                />
                </Box>
              </>
            } 
          />
        <div ref={orderRef}>
          <div style={{
            display: "flex",
            alignItems: "center",
            marginBottom:'5px'
          }}>
            <Box sx = {{ marginLeft: {
      xs: '10px', 
      sm: '180px', 
    }, }}>
              Show Entries:
            </Box>
            <Dropdown
              options={showEntries}
              selectedValue={pageSize.toString()}
              handleChange={(event) => updateField(event)}
              width='90px'
              height='30px'
            />
            <div style={{ marginLeft: "auto", marginRight: "20px" }}>
              {totalRecords === 0 && (
                <div style={{marginLeft : '80px' }}>
                  Showing 0 of {totalRecords} entries
                </div>
              )}
              {totalRecords > 0 && (
                <div style={{marginLeft : '80px'}}>
                  Showing {startIndex} to {endIndex} of {totalRecords} entries
                </div>
              )}
            </div>
            <Button 
              variant="contained" 
              style={{ backgroundColor: 'Green', color: 'white', marginRight:'5px' }}
              size="small"
              onClick={() => handleStartTrip()}
            >
              Start Trip
            </Button>
            <Button className='custom-button' style={{height:'25px'}} onClick={exportToExcel}>
              <DownloadIcon/>
            </Button>
          </div>
          <Box className="table-container custom-data-grid"  sx = {{ marginLeft: {
      xs: '10px', 
      sm: '180px', 
    },
            marginRight: '40px',
            marginTop:'10px',
            position: 'relative',
            height: '450px',
            overflowY: 'auto',
          }}>
            <DataGrid
              rows={rows}
              columns={columns}
              rowCount={totalRecords}
              autoHeight={false}
              disableRowSelectionOnClick
              disableColumnResize
              hideFooterPagination
              classes={{
                columnHeader: 'red-header',
              }}
            />
          </Box>
          <div style={{
            marginTop: '8px',
            marginLeft: '50%'
          }}>
            <Newpagination
              rows={totalRecords}
              pageSize={pageSize}
              page={page}
              handlePageChange={handlePageClick}
            />
          </div>
          <ConfirmationPopup
            open={confirmOpen}
            onClose={handleCloseConfirm}
            onConfirm={handleConfirm}
            title="Confirm Changes"
            message="Are you sure you want to apply these changes?"
          />
          <LocationModal
            open={modalOpen}
            onClose={handleCloseModal}
            startLocation={modalStartLocation}
            endLocation={modalEndLocation}
          />
          <TripDetailsModal
            isOpen={tripDetailsModalOpen}
            onClose={handleTripDetailsCloseModal}
            type={modalType || 'start'}
            tripId={tripId}
            vehicleId={vehicleId}
            vehicleList={vehicleList}
          />
        </div>
      </div>
      <NavBar selected='Trips' />
    </>
  );
};
export default Trips;