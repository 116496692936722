import React, { useEffect, useState } from 'react';
import './sidenav.scss';
import { IconContext } from 'react-icons';
import * as AiIcons from 'react-icons/ai';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';

type Props = {
  selected?: string;
};

const NavBar: React.FC<Props> = ({ selected }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const SidebarData = require('./sidebarData').default;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    setDrawerOpen(open);
  };

  return (
    <>
      {windowWidth > 600 && (
        <IconContext.Provider value={{ color: '#fff' }}>
          <div className="sidebar">
            <a className="navbar-brand" href="https://trackyo.in">
              <img src="app_logo.png" alt="trackyo_logo" style={{ width: '6rem', margin: '20px' }} />
            </a>
            {SidebarData.map((item: any, index: number) => (
              <a
                key={index}
                href={item.path}
                className={item.title === selected ? 'highlight' : 'sidebar-section'}
              >
                {item.icon}&nbsp;
                <span>{item.title}</span>
              </a>
            ))}
          </div>
        </IconContext.Provider>
      )}
      {windowWidth <= 600 && (
        <>
          <Box className="topnav">
            <AiIcons.AiOutlineBars onClick={toggleDrawer(true)} style={{ fontSize: '2rem', cursor: 'pointer' }} />
            <a className="navbar-brand" href="https://trackyo.in">
              <img src="app_logo.png" alt="trackyo_logo" style={{ width: '3.5rem', marginLeft: '10px' }} />
            </a>
          </Box>
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
          >
            <Box sx={{height:'100%' ,  background: 'linear-gradient(to bottom, #30dcff, #1be0d9, #0df0ee)',
            }} role="presentation"
            >
             <List>
              <ListItem>
               
                <a className="navbar-brand" href="https://trackyo.in">
                  <img src="app_logo.png" alt="trackyo_logo" style={{ width: '3.5rem' }} />
                </a>
                </ListItem>
    
                <div className="sidebar">
                <AiIcons.AiOutlineArrowLeft
                  style={{ fontSize: '1.5rem', cursor: 'pointer', marginRight: '10px' }}
                  onClick={toggleDrawer(false)}
                />
            <a className="navbar-brand" href="https://trackyo.in">
              <img src="app_logo.png" alt="trackyo_logo" style={{ width: '6rem', margin: '20px' }} />
            </a>
                {SidebarData.map((item: any, index: number) => (
                
                    <a
                    key={index}
                      href={item.path}
                      className={item.title === selected ? 'highlight' : 'sidebar-section'}
                    >
                      {item.icon}&nbsp;
                      <span>{item.title}</span>
                    </a>
             
                ))}
                  </div>
              </List>
            </Box>
          </Drawer>
        </>
      )}
    </>
  );
};

export default NavBar;
