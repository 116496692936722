import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Routes ,Route } from 'react-router-dom';
import './Components/globalVariables.js';
import LoginPage from './Components/Login/login.tsx';
import Trips from './Pages/Trips/trips.tsx';
import Dashboard from './Pages/Dashboard/dashboard.tsx';
// import Users from './Pages/Users/users.tsx';
import Vehicles from './Pages/Vehicles/vehicle.tsx';
import Users from './Pages/Users/users.tsx';
// import DashboardScreen from './Pages/WebView/start_trip_webview.tsx';
import DocumentsScreen from './Pages/WebView/Pages/WebViewDocuments/web_view_documents.tsx';
import OffersScreen from './Pages/WebView/Pages/WebViewOffers/web_view_offers.tsx';
import OrdersScreen from './Pages/WebView/Pages/WebViewOrders/web_view_orders.tsx';
import RoutesScreen from './Pages/WebView/Pages/WebViewRoutes/web_view_routes.tsx';
import TripsScreen from './Pages/WebView/Pages/WebViewTrips/web_view_trips.tsx';
import ShowMoreTrips from './Pages/WebView/Pages/WebViewTrips/show_more_trips.tsx';
import Company from './Pages/Company/company.tsx';
// import ClientsPage from './Pages/Clients/clients.tsx';
//  import VendorsPage from './Pages/Vendors/vendors.tsx';

function App() {
  return (
    <div className="App">
     <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/" element={<LoginPage />} />
        <Route path ="/trips" element={<Trips/>} />
        <Route path = "/vehicles" element ={<Vehicles/>} />
        <Route path = "/users" element ={<Users/>} />
        {/* <Route path = "/start_trip_web_view" element ={<DashboardScreen/>} /> */}
        <Route path = "/web_view_documents" element ={<DocumentsScreen/>} />
        <Route path = "/web_view_trips" element ={<TripsScreen/>} />
        <Route path = "/web_view_offers" element ={<OffersScreen/>} />
        <Route path = "/web_view_show_more" element ={<ShowMoreTrips/>} />
        <Route path = "/web_view_orders" element ={<OrdersScreen/>} />
        <Route path = "/web_view_routes" element ={<RoutesScreen/>} />
        <Route path = "/company" element ={<Company/>} />
        {/* <Route path = "/clients" element= {<ClientsPage/>}/>
        <Route path = "/vendors" element= {<VendorsPage/>}/> */}
      </Routes>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick pauseOnHover />
    </div>
  );
}
export default App;
