import React, { useState } from 'react';
import { IconButton, Typography, Menu, MenuItem, useMediaQuery } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './heading.scss';

interface HeadingProps {
  title: string;
  closeIcon: string;
  onClose?: () => void;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  page?: string; // Add a prop to identify the page
}

const Heading: React.FC<HeadingProps> = ({ title, closeIcon, onClose, leftContent, rightContent, page }) => {
  const pageName = ['vehicles', 'trips', 'users'];
  const breakpoint = pageName.includes(page || '') ? 1290 : 600;
  const isMobile = useMediaQuery(`(max-width:${breakpoint}px)`);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleContentClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const hasDropdownContent = leftContent || rightContent;

  return (
    <div className="heading">
      <div className="title-align">
        {isMobile ? (
          <>
            <div className="title-with-icon">
              <Typography fontWeight="bold" variant="h6" component="div">
                {title}
              </Typography>
              {hasDropdownContent && (
                <IconButton
                  aria-label="menu"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleMenuOpen}
                >
                  <ArrowDropDownIcon />
                </IconButton>
              )}
            </div>
            {hasDropdownContent && (
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {leftContent && (
                  <MenuItem onClick={handleMenuClose}>
                    <div onClick={handleContentClick}>{leftContent}</div>
                  </MenuItem>
                )}
                {rightContent && (
                  <MenuItem onClick={handleMenuClose}>
                    <div onClick={handleContentClick}>{rightContent}</div>
                  </MenuItem>
                )}
              </Menu>
            )}
          </>
        ) : (
          <>
            {leftContent && <div className="left-content">{leftContent}</div>}
            <Typography fontWeight="bold" variant="h6" component="div" className="title">
              {title}
            </Typography>
            {rightContent && <div className="right-content">{rightContent}</div>}
          </>
        )}
        {closeIcon !== 'false' && onClose && (
          <IconButton onClick={onClose} aria-label="close" className="close-icon">
            <CancelIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default Heading;
