import React, {useState} from 'react';
import Heading from '../../Components/Heading/heading.tsx';
import '../../Components/Button/button-style.scss';
import {Dialog,  DialogContent, DialogTitle, IconButton, Tooltip, Table, TableBody, TableCell, TableRow, Box} from '@mui/material';
import UploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import DatePicker from '../../Components/DatePicker/date-picker.tsx';
import ConfirmationPopup from '../../Components/ConfirmationModal/confirmation-modal.tsx';
import {addDocuments ,deleteDocument, editDocuments} from './action.ts';
import {toast} from 'react-toastify';

interface AddDocumentModalProps {
    isDocumentOpen: boolean;
    vehicleId : number;
    handleClose: () => void;  
}
interface UploadedFile {
  location: string;
  id : number;
}

const AddDocumentModal: React.FC<AddDocumentModalProps> = ({ isDocumentOpen ,handleClose, vehicleId}) => {
  const maxFileSize = 5 * 1024 * 1024; 
  const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
  const [insuranceExpiryDate, setInsuranceExpiryDate] = useState<string>('');
  const [fitnessExpiryDate, setFitnessExpiryDate] =  useState<string>('');
  const [initialInsuranceDate, setInitialInsuranceDate] = useState<any>('');
  const [initialFitnessDate, setInitialFitnessDate] = useState<any>('');
  const [selectedFiles, setSelectedFiles] = useState<{ [key: string]: File | null }>({
    registrationCertificate: null,
    pollutionCertificate: null,
    fitnessCertificate: null,
    insurance: null,
    drivers_license: null,
    aadhar: null
  });
  const [uploadedFiles, setUploadedFiles] = useState<{ [key: string]: UploadedFile | null }>({
    registrationCertificate: null,
    pollutionCertificate: null,
    fitnessCertificate: null,
    insurance: null,
    drivers_license: null,
    aadhar: null
  });
 const [icons, setIcons] = useState<{ [key: string]: string }>({
    registrationCertificate: 'upload',
    pollutionCertificate: 'upload',
    fitnessCertificate: 'upload',
    insurance: 'upload'
  });
  const resetState = () => {
      setSelectedFiles({
      registrationCertificate: null,
      pollutionCertificate: null,
      fitnessCertificate: null,
      insurance: null,
      drivers_license: null,
      aadhar: null,
     });
    setUploadedFiles({
      registrationCertificate: null,
      pollutionCertificate: null,
      fitnessCertificate: null,
      insurance: null,
      drivers_license: null,
      aadhar: null,
    });
    setInsuranceExpiryDate('');
    setFitnessExpiryDate('');
    setIcons({
      registrationCertificate: 'upload',
      pollutionCertificate: 'upload',
      fitnessCertificate: 'upload',
      insurance: 'upload',
    });
  };
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [docIdToDelete, setDocIdToDelete] = useState(0);
  const [docTypeToDelete, setDocTypeToDelete] = useState<string | null>(null);
  const handlePopUpClose = () => {
     resetState();
     handleClose() ;
  };
  const handleOpenDeleteModal = (id: number, type: string) => {
    setDocIdToDelete(id);
    setDocTypeToDelete(type);
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setDocIdToDelete(0);
    setDocTypeToDelete(null);
  };
  const handleConfirmDelete = () => {
      if (docIdToDelete && docTypeToDelete) {
             deleteDocument(
                docIdToDelete, 
                vehicleId, 
                (response) => {
                     toast.success(`${docTypeToDelete.replace('_', ' ')} removed successfully`);
                     setUploadedFiles((prevFiles) => ({
                           ...prevFiles,
                           [docTypeToDelete]: null
                      }));
                      setSelectedFiles((prevFiles) => ({
                        ...prevFiles,
                        [docTypeToDelete]: null
                    }));
                     setIcons((prevIcons) => ({
                         ...prevIcons,
                         [docTypeToDelete]: 'upload'
                      }));
                      if (docTypeToDelete === 'insurance') {
                        setInsuranceExpiryDate('');
                      }
                      if ( docTypeToDelete === 'fitnessCertificate') {
                        setFitnessExpiryDate('');
                        }
                     handleCloseDeleteModal();
                },
                (errorMessage) => {
                     console.error('Error deleting document:', errorMessage);
                     toast.error(`Error removing ${docTypeToDelete?.replace('_', ' ')}`);
                     handleCloseDeleteModal();
                }
             );
        }
  };
  const uploadFile = (type: string, file: File, fitnessDate: string, insuranceDate: string, action: string) => {
       let specificInsuranceDate = '';
       let specificFitnessDate = '';
       if (file) {
              if (type === 'fitnessCertificate') {
                specificFitnessDate = fitnessDate; 
              }
              if (type === 'insurance') {
                specificInsuranceDate = insuranceDate; 
                } 
                if(action === 'add'){
                addDocuments(
                        type === 'drivers_license' ? file : null,
                        type === 'aadhar' ? file : null,
                        type === 'registrationCertificate' ? file : null,
                        type === 'insurance' ? file : null,
                        type === 'fitnessCertificate' ? file : null,
                        type === 'pollutionCertificate' ? file : null,
                        vehicleId,
                        specificFitnessDate,
                        specificInsuranceDate,
                        (response) => {
                              setUploadedFiles((prevFiles) => ({
                                   ...prevFiles,
                                   [type]: { location: response.message.location, id: response.message.id }
                              }));
                              toast.success(`${type.replace('_', ' ')} uploaded successfully`);
                              },
                        (errMessage: any) => {  
                            if (errMessage && errMessage.status === 400) {
                                   let allErrorMessages: string[] = [];
                                   if (typeof errMessage.message === 'string') {
                                            allErrorMessages.push(errMessage.message);
                                            setSelectedFiles((prevFiles) => ({
                                              ...prevFiles,
                                              [type]: file
                                             }));
                                            setIcons((prevIcons) => ({
                                              ...prevIcons,
                                              [type]: 'view'
                                            }));
                                            toast.error(allErrorMessages.join(" "));
                                    } else if (errMessage.message && typeof errMessage.message === 'object') {
                                          const errors = errMessage.message.errors || {};
                                          for (const [key, messages] of Object.entries(errors)) {
                                             if (Array.isArray(messages)) {
                                                allErrorMessages.push(...messages);
                                              }
                                          }
                                          setSelectedFiles((prevFiles) => ({
                                               ...prevFiles,
                                               [type]: null
                                          }));
                                          setIcons((prevIcons) => ({
                                            ...prevIcons,
                                            [type]: 'upload'
                                          }));
                                          toast.error(allErrorMessages.join(" "));
                                     }
                          } else {
                            toast.error("An unexpected error occurred.");
                }
         }
       );
      }
      else {
        editDocuments(
          type === 'drivers_license' ? file : null,
          type === 'aadhar' ? file : null,
          type === 'registrationcertificate' ? file : null,
          type === 'insurance' ? file : null,
          type === 'fitnessCertificate' ? file : null,
          type === 'pollutionCertificate' ? file : null,
          vehicleId,
          fitnessDate,
          insuranceDate,
          (response) => {
              if (response.status === 200) {           
                  setSelectedFiles((prevFiles) => ({
                    ...prevFiles,
                    [type]: file                 
                  }));
                  setIcons((prevIcons) => ({
                    ...prevIcons,
                    [type]: 'view'
                  }));
                  toast.success("Document Uploaded Successfully");
                    
               
                  }              
          },
          (errMessage: any) => {  
            let allErrorMessages: string[] = [];
            if (errMessage.status === 400) {
              
              if (typeof errMessage.message === 'string') {
                allErrorMessages.push(errMessage.message);
                setSelectedFiles((prevFiles) => ({
                  ...prevFiles,
                  [type]: file
                }));
                setIcons((prevIcons) => ({
                  ...prevIcons,
                  [type]: 'view'
                }));
                toast.error(allErrorMessages.join(" "));
              } 
              else {
                toast.error(errMessage.message);
              }
            }
              else if (errMessage.status =413 ){
                toast.error("Document Size is large");
              }
              else if (errMessage.message && typeof errMessage.message === 'object') {
                const errors = errMessage.message.errors || {};
                for (const [key, messages] of Object.entries(errors)) {
                  if (Array.isArray(messages)) {
                    allErrorMessages.push(...messages);
                  }
                }
                setSelectedFiles((prevFiles) => ({
                  ...prevFiles,
                  [type]: null
                }));
                setIcons((prevIcons) => ({
                  ...prevIcons,
                  [type]: 'upload'
                }));
                toast.error(allErrorMessages.join(" "));
              }
             
            } 
          )};
      
   }
  }
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const fileInput = event.target; 
    const file = fileInput.files?.[0] || null;
    setSelectedFiles((prevFiles) => ({
        ...prevFiles,
        [type]: file
    }));
    setIcons((prevIcons) => ({
        ...prevIcons,
        [type]: file ? 'view' : 'upload'
    }));
    if (file) {
      if (file.size > maxFileSize) {
          toast.error("File size must not exceed 1 MB");
          setSelectedFiles((prevFiles) => ({
            ...prevFiles,
            [type]: null
        }));
        setIcons((prevIcons) => ({
          ...prevIcons,
          [type]: 'upload'
         })); 
      }
      else if (!allowedFileTypes.includes(file.type)) {
        toast.error("File type not supported");
        setSelectedFiles((prevFiles) => ({
                ...prevFiles,
                [type]: null
        }));
        setIcons((prevIcons) => ({
        ...prevIcons,
        [type]: 'upload'
       }));
    }
   else  if (vehicleId && file) {
       if (type === 'fitnessCertificate' && !fitnessExpiryDate) {
          toast.error('Expiry date is required for Fitness Certificate');
        } else if (type === 'insurance' && !insuranceExpiryDate) {
          toast.error('Expiry date is required for Insurance Certificate');
          } 
    else  {
          uploadFile(type, file, fitnessExpiryDate, insuranceExpiryDate,'add');
        }
      }
      fileInput.value = '';
    }
  };
  const handleIconClick = (type: string, action: string) => {
     if (action === 'view') {
            const uploadedFile = uploadedFiles[type];
            if (uploadedFile && uploadedFile.location) {
                const fileUrl = `${global.documents_image_url}${uploadedFile.location}`;
                console.log('File URL:', fileUrl);
                if (fileUrl) {
                    window.open(fileUrl, '_blank'); 
                } else {
                    toast.error('File URL is not available');
                }
            } else {
              toast.error('No location available for this document');
            }
        } else if (action === 'remove' && uploadedFiles[type]) {
                const id = uploadedFiles[type]?.id;
            if (id !== undefined) {
              handleOpenDeleteModal(id, type);
            }
    }
 };
 const handleInsuranceExpiryDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInsuranceExpiryDate(value);
    if (!selectedFiles.insurance && value) {
      toast.error("Choose file to upload insurance certificate");
    }
    else if (selectedFiles.insurance && value) {
      if (!initialInsuranceDate) {
        setInitialInsuranceDate(event.target.value);
        uploadFile('insurance', selectedFiles?.insurance, '', value,'add');
      } else {

        uploadFile('insurance',selectedFiles?.insurance,'',value,'edit');
      }    
      }
 };
 const handleFitnessExpiryDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
       const { name, value } = event.target;
       setFitnessExpiryDate(value);
       if (!selectedFiles.fitnessCertificate && value) {
        toast.error("Choose file to upload fitness certificate");
      }
      else if (selectedFiles.fitnessCertificate && value) {
        if(!initialFitnessDate){
          setInitialFitnessDate(value);
        uploadFile('fitnessCertificate', selectedFiles?.fitnessCertificate, value,'','add');
        }else{
          uploadFile('fitnessCertificate',selectedFiles?.fitnessCertificate,value,'','edit');
        }
      }
};
const renderFileInput = (label: string, type: string) => (
  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    <p style={{ fontSize: '17px', margin: 0, marginRight: '10px' }}>{label}</p>
    <input
      accept="*"
      style={{ display: 'none' }}
      id={`${type}-file`}
      type="file"
      onChange={(e) => handleFileChange(e, type)}
    />
    <label htmlFor={`${type}-file`}>
      <Tooltip title="Upload File">
        <IconButton color="primary" component="span">
          {icons[type] === 'upload' && <UploadIcon />}
        </IconButton>
      </Tooltip>
    </label>
    {icons[type] === 'view' && (
      <>
        <Tooltip title="View File">
          <span
            style={{ cursor: 'pointer', color: 'blue', marginRight: '10px' }}
            onClick={() => handleIconClick(type, 'view')}
          >
            View
          </span>
        </Tooltip>
        <Tooltip title="Remove File">
          <IconButton sx={{ color: 'red' }} component="span" onClick={() => handleIconClick(type, 'remove')}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </>
    )}
  </div>
);

 return (
        <Dialog open={isDocumentOpen} onClose={handlePopUpClose} fullWidth
        maxWidth={false}
        PaperProps={{
          sx: {
            width: '700px', 
            height: 'auto', 
          }
        }}>
        <DialogTitle>
        <Box
        sx={{
          marginLeft: {
            xs: '0px',
            sm: '-180px',
          },
        }}
      >               <Heading title="Add Document" closeIcon="true" onClose={handlePopUpClose} />
            </Box>
        </DialogTitle>
        <DialogContent>
        <Table style={{ border: '3px solid black' }}>
          <TableBody>
            <TableRow>
              <TableCell  colSpan={2}>
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  {renderFileInput('Registration Certificate (RC)', 'registrationCertificate')}
                </div>
              </TableCell>
              <TableCell colSpan={2}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  {renderFileInput('Pollution Certificate', 'pollutionCertificate')}
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  {renderFileInput('Insurance', 'insurance')}
                </div>
              </TableCell>
              <TableCell colSpan={2}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  <span>Expiry Date</span>
                  <DatePicker
                    id="insuranceDate"
                    name="insuranceDate"
                    value={insuranceExpiryDate}
                    label=""
                    onChange={handleInsuranceExpiryDateChange}
                  />
                </div>
              </TableCell> 
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  {renderFileInput('Fitness Certificate (FC)', 'fitnessCertificate')}
                </div>
              </TableCell>
              <TableCell colSpan={2}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  <span>Expiry Date</span>
                  <DatePicker
                    id="fitnessDate"
                    name="fitnessDate"
                    value={fitnessExpiryDate}
                    label=""
                    onChange={handleFitnessExpiryDateChange}
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <ConfirmationPopup
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        onConfirm={handleConfirmDelete}
        title="Confirm Deletion"
        message={`Are you sure you want to delete this ${docTypeToDelete?.replace('_', ' ')}?`}
      />
      </DialogContent>
      </Dialog>
    );
};

export default AddDocumentModal;
